import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ChaiDropDown from '../ChaiDropDown';
import ChaiBodyContainer from '../ChaiBodyContainer';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiBodyContainerHeader from '../ChaiBodyContainerHeader';
import ChaiBaseForm from '../ChaiBaseForm';
import ChaiFooterContainer from '../ChaiFooterContainer';
import ChaiSwitch from '../ChaiSwitch';
import LinearProgress from '@material-ui/core/LinearProgress';
import ChaiImageInput from '../ChaiImageInput';
import { getAdministrative, saveAdministrative } from './service';
import ChaiTextField from "../ChaiTextField";
import ChaiRadioButton from "../ChaiRadioButton";
import chaiErrorHandler from '../../Handler/chaiErrorHandler';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import formNames from '../ChaiBaseForm/formNames';

const styles = theme => ({
    radio: { fontFamily: '"Source Sans Code", sans-serif', fontSize: '0.95em', color: '#3f3e3e' },
    radioContainer: { width: '32%', display: 'flex', padding: '20px', alignItems: 'center' }
});

class ChaiAdministrative extends ChaiBaseForm {
    constructor(props) {
        let formName = formNames.ADMINISTRATIVE;
        const user = JSON.parse(localStorage.getItem('user'));
        const brands = user.brands;
        let emptyDataState = {
            lwinCode: null,
            qrCode: null,
            rfidCode: null,
            availableForSale: false,
            updateConditionReport: false,
            updateOwnership: false,
            encryptOwnerName: false,
            encryptOwnerInformation: false,
            makeLedgerPrivate: false,
            capsuleBatch: '',
            sellerLogo: '',
            sellerLink: '',
            sellerName: '',
            showLogo: false
        };

        super({ ...props, formName, emptyDataState });
        const selectBrands = this.formatBrands(brands);
        this.state = {
            ...this.state,
            dialogOpen: false,
            dialogMessage: null,
            pairedRfid: null,
            brands: brands,
            selectBrands,
            activeBrand: null,
            softLoad: true,
        };
    }

    formatBrands = (brands) => {
        return brands.map((brand, index) => {
            return { id: index, description: brand.name }
        })

    }

    getElement = async (ledger, version) => {
        return getAdministrative(ledger.ledgerNumber, version)
            .then(result => {
                const administrative = (result ? result : this.emptyDataState)
                return { ...administrative }
            });
    };

    saveElement = async (ledger, version, element) => {
        return saveAdministrative(ledger.ledgerNumber, version, element);
    };

    handleForSale = event => {
        let data = { ...this.state.data };
        data.availableForSale = event.target.value === 'public';
        this.updateParentForm(data);
        this.setState({ data });
        console.log('state and event', this.state, event.target.value)
    };

    saveOrHandleValidation = async () => {
        const { userRole } = this.state;
        const isCollector = userRole === 'Collector';

        if (!isCollector && !this.validateQrCode()) {
            this.props.showNotification('Invalid QR Code Hash or url.');
            return;
        }
        if (!isCollector && !this.validateSellerLink()) {
            this.props.showNotification('Please enter a valid SELLER LINK url including prefix, e.g.: https://');
            return;
        }

        let data = this.state.data;
        data.qrCode = data.qrCode.indexOf('/') > -1 ? data.qrCode.substr(data.qrCode.lastIndexOf('/') + 1) : data.qrCode;
        this.setState({ data });


        this.setLoadingState(true);
        console.log("SAVING DATA");
        try {
            console.log("SENDING");
            await this.saveElement(this.props.currentLedger, 0, this.state.data);
            console.log("COMPLETED RESPONSE");
            this.setLoadingState(false);
            this.props.handleNextStep();
        } catch (e) {
            console.log("CAUGHT ERROR IN BASE FORM: ", e);
            this.setLoadingState(false);

            if (!this.handleRFIDError(e)) {
                //We provide a notifier for the error handler so it displays the error once its handled
                chaiErrorHandler(this.notify).catch(e);
            }
        }
    };

    handleRFIDError = err => {
        let errorDetail = (err.content.additionalData || { pairedRfid: null });
        if (errorDetail.pairedRfid) {
            this.setState({
                pairedRfid: errorDetail.pairedRfid,
                dialogMessage: `${err.content.message}: ${err.content.content[0]}, do you want to replace it?`,
                dialogOpen: true
            });
            return true;
        }
        return false;
    };

    handleDialogCancel() {
        this.setState({ dialogOpen: false, dialogMessage: null, pairedRfid: null });
        this.setLoadingState(false);
    }

    handleDialogOk() {
        this.setState({ dialogOpen: false, dialogMessage: null, pairedRfid: null });
        this.state.data.overrideRFIDCode = true;
        this.saveOrHandleValidation();
    }

    handleRfidChange = (e) => {
        if (e.key === 'e') {
            e.preventDefault();
        } else {
            let data = this.state.data;
            data.rfidCode = e.target.value;
            this.setState({ data });
        }
    }

    validateQrCode = () => {
        const { qrCode } = this.state.data;
        const pattern = /^([a-z0-9]+){4,}/;

        return pattern.test(qrCode.indexOf('/') > -1 ? qrCode.substr(qrCode.lastIndexOf('/') + 1) : qrCode);
    };

    validateSellerLink = () => {
        const { showLogo, sellerLink } = this.state.data;
        return showLogo || (
            sellerLink !== null &&
            (sellerLink.indexOf('http://') > -1 || sellerLink.indexOf('https://') > -1)
        );
    };

    setActiveBrand = (ev) => {
        const data = this.state.data;
        data.sellerLink = this.state.brands[ev.target.value].link;
        data.sellerName = this.state.brands[ev.target.value].name;
        data.sellerLogo = this.state.brands[ev.target.value].image;
        this.setState({ ...data, activeBrand: ev.target.value });
    }

    calculateNonOwnerWritePermission = (field) => {
        if (this.isOwnerOrCreator() || this.isAdmin()) {
            return this.calculateWritePermissionForms(field);
        } else {
            return false;
        }
    }

    render() {
        const user = JSON.parse(localStorage.getItem('user'));

        return (
            <Fragment>
                {this.state.loading && <LinearProgress />}
                {this.state.dialogOpen && <Dialog
                    maxWidth="xs"
                    aria-labelledby="confirmation-dialog-title"
                    open={this.state.dialogOpen}
                >
                    <DialogTitle id="confirmation-dialog-title">Ledger already paired</DialogTitle>
                    <DialogContent dividers>
                        <p>{this.state.dialogMessage}</p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDialogCancel()} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => this.handleDialogOk()} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>}
                <ChaiBodyContainer>
                    <ChaiBodyContainerHeader {...this.props}>
                        <ChaiRowBodyContainer>
                            <ChaiTextField
                                label="Wine ID (LWIN DB code)*"
                                {...this.getField('lwinCode')}
                                style={{ width: '30%' }}
                                disabled={true}
                            />
                            <ChaiTextField
                                label="Chai Vault QR code*"
                                {...this.getField('qrCode')}
                                style={{ width: '30%' }}
                                placeholder="Enter full url or hash..."
                            />
                            <ChaiTextField
                                label="RFID code*"
                                {...this.getField('rfidCode')}
                                style={{ width: '30%' }}
                                onChange={e => this.handleRfidChange(e)}
                            />
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '29.5%',
                                border: '1px solid gray',
                                marginLeft: '2px'
                            }}>
                                <ChaiRadioButton
                                    label="Make Ledger Available For Sale / Viewable by Public*"
                                    checked={this.state.data.availableForSale}
                                    onChange={this.handleForSale}
                                    value="public"
                                    name="availableForSale"
                                    ariaLabel="public"
                                    style={{ padding: '20px' }}
                                    disabled={this.state.loading || !this.calculateWritePermissionForms('availableForSale')}
                                />
                                <ChaiRadioButton
                                    label="Make Ledger Private (No longer viewable by public)*"
                                    checked={!this.state.data.availableForSale}
                                    onChange={this.handleForSale}
                                    value="private"
                                    name="availableForSale"
                                    ariaLabel="private"
                                    style={{ padding: '20px' }}
                                    disabled={this.state.loading || !this.calculateWritePermissionForms('availableForSale')}
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                                <ChaiSwitch
                                    label='Encrypt Owner Name*'
                                    {...this.getField('encryptOwnerName', true)}
                                    style={{ width: '100%', padding: '20px' }}
                                    disabled={this.state.loading || !this.calculateNonOwnerWritePermission('encryptOwnerName')}
                                />
                                <ChaiSwitch
                                    label='Encrypt Owner Information*'
                                    {...this.getField('encryptOwnerInformation', true)}
                                    style={{ width: '100%', padding: '20px' }}
                                    disabled={this.state.loading || !this.calculateNonOwnerWritePermission('encryptOwnerInformation')}
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                                <ChaiSwitch
                                    label='Update Condition Report'
                                    {...this.getField('updateConditionReport', true)}
                                    style={{ width: '100%', padding: '20px' }}

                                />
                                <div style={{ width: '100%', padding: '20px' }}><br /><br /><br /></div>
                            </div>
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer>
                            <ChaiSwitch
                                label='This user does not want to display a logo'
                                {...this.getField('showLogo', true)}
                                style={{ width: '100%' }}
                            />
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer>
                            <ChaiTextField
                                label="Capsule Batch*"
                                {...this.getField('capsuleBatch')}
                                style={{ width: '15%' }}
                            />
                            <ChaiTextField
                                label="Seller's Name"
                                {...this.getField('sellerName')}
                                disabled={this.state.data.showLogo}
                                style={{ width: '15%' }}
                            />
                            <ChaiTextField
                                label="Seller's Link"
                                {...this.getField('sellerLink')}
                                style={{ width: '20%' }}
                                disabled={this.state.data.showLogo}
                            />
                            {
                                user.rol !== 'Collector' &&
                                <>
                                    <ChaiImageInput
                                        {...this.getFileField('sellerLogo')}
                                        label="Seller's Logo"
                                        disabled={this.state.data.showLogo}
                                    />

                                    <ChaiDropDown
                                        disabled={this.state.data.showLogo}
                                        onChange={this.setActiveBrand}
                                        value={this.state.activeBrand}
                                        label="Select Brand" style={{ width: '20%' }}
                                        items={this.state.selectBrands}
                                    />
                                </>
                            }
                        </ChaiRowBodyContainer>
                    </ChaiBodyContainerHeader>
                </ChaiBodyContainer>
            </Fragment>
        );
    }
}

ChaiAdministrative.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiAdministrative);
