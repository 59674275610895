import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import classNames from 'classnames';
import debounce from 'debounce';

import { bottleCaseQrCodeExists, bottleCaseRfidCodeExists } from './service';

import { filterWine, addWine } from '../ChaiBottleInformation/service';

import ChaiAutoComplete from '../ChaiAutoComplete';
import ChaiBaseForm from '../ChaiBaseForm';
import ChaiBodyContainer from '../ChaiBodyContainer';
import ChaiDropDown from '../ChaiDropDown';
import chaiErrorHandler from '../../Handler/chaiErrorHandler';
import ChaiFooterContainer from "../ChaiFooterContainer";
import ChaiHeaderButton from '../ChaiHeaderButton';
import ChaiImageInput from '../ChaiImageInput';
import ChaiRadioButton from '../ChaiRadioButton';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiTextField from '../ChaiTextField';

import formNames from '../ChaiBaseForm/formNames';
import { DEF_NUM_BOTTLES, MAX_NUM_BOTTLES } from '../../constants.js';
import { newLedger } from '../ChaiLedger/ledger';

const styles = theme => ({
    filler: {
        background: '#efefef',
        width: 'auto',
        padding: '20px',
        display: 'flex',
        minHeight: '200px'
    },
    multipleBottleEntryFields: {
        height: '328px',
        overflow: 'hidden',
        transition: '200ms'
    },
    hide: {
        height: 0,
        opacity: 0
    }
});

class ChaiSearchScreen extends ChaiBaseForm {
    constructor(props) {
        let formName = formNames.BOTTLEINFORMATION;
        let emptyDataState = {
            lwinCode: null,
            barCode: null,
            typeOfInput: 1,
            wineName: null,
            vintage: null,
            bottleSize: 2,
            country: null,
            producer: null,
            region: null,
            subRegion: null,
            appellation: null,
            wineType: 0,
            wineColor: 1,
            classification: null,
            varietalOrBlend: null,
            bottledByAt: 1,
            bottledByAtDescription: null,
            countryDescription: null,
            classificationDescription: null,
            //wineModal: false,
            newWineName: '',
            newWineProducer: '',
            newWineCountry: 0,
            newWineCountryDesc: '',
            newWineRegion: '',
            newWineSubregion: '',
            newWineColor: 0,
            newWineType: 0,
            newWineDesignation: '',
            newWineClassification: 0,
            newWineClassificationDesc: '',
            newWineAppellation: '',
            newWineBottleSize: 0,
            newWineVarietal: '',
            loading: false,
            caseId: '',
            caseNumberOfBottles: DEF_NUM_BOTTLES,
            caseRFIDCode: '',
            caseQRCode: '',
            caseInformation: 3
        };

        super({ ...props, formName, emptyDataState });
        this.state = {
            ...this.state,
            bottleEntryMode: 'single',
            query: '',
            results: [],
            wineModal: false
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getOptionsWithFormNameFieldName('searchScreen', 'caseInformation')
            .then(field => this.setState({ caseInformationOptions: field }));
        (async () => { await this.getOptionsWithFormName(formNames.BOTTLEINFORMATION); })();
    }

    getElement = async (ledger, version) => {
        localStorage.removeItem('bottleInfo');
        localStorage.removeItem('authInfo');

        await this.getOptionsWithFormName(formNames.BOTTLEINFORMATION);
        return this.emptyDataState;
    }

    // For AutoComplete Component
    getInfo = async () => {
        const query = this.state.query;
        try {
            if (query) {
                const result = await filterWine(query, 20);
                let data = [{ wine: 'No results' }];

                if (result && result.length) {
                    data = result;
                }
                data = [...data, { wine: '+Add new wine' }];
                this.setState({ results: data });
            }
        } catch (error) {
            this.props.showNotification(error.message);
        }
    };

    searchDelayed = debounce(this.getInfo, 500);

    handleSearchChange = (e) => {
        let update = {
            query: e.target.value
        };

        if (!e.target.value) {
            update.results = [];
        }

        this.setState(update);
        this.searchDelayed();
    };

    handleSearchSelect = async (item) => {
        if (item.wine === '+Add new wine') {
            this.setState({ wineModal: true, query: '', results: [] });
            return;
        }

        let data = this.state.data;
        const formName = formNames.BOTTLEINFORMATION;
        const country = (this.state.options[formName]['country'] || []).find(x => x.description.toLowerCase() === item.country.toLowerCase());
        const wineColor = (this.state.options[formName]['wineColor'] || []).find(x => x.description.toLowerCase() === item.colour.toLowerCase());
        const wineType = (this.state.options[formName]['wineType'] || []).find(x => x.description.toLowerCase() === item.type.toLowerCase());
        const classification = (this.state.options[formName]['classification'] || []).find(x => x.description.toLowerCase() === item.classification.toLowerCase());
        const bottleSize = (this.state.options[formName]['bottleSize'] || []).find(x => x.id === item.bottleSize);

        data.lwinCode = item.lwinCode;
        data.producer = item.producer;
        data.wineName = item.wine;
        data.country = country ? country.id : 18; // If not found, default to 18: OTHER
        data.region = item.region;
        data.subRegion = item.subRegion;
        data.wineColor = wineColor ? wineColor.id : 1;
        data.wineType = wineType ? wineType.id : 1;
        data.classification = classification ? classification.id : 1; // If not found, default to 1 = None
        data.appellation = item.appellation || '';
        data.bottleSize = item.bottleSize || 2;
        data.varietalOrBlend = item.varietal || '';
        data.typeOfInput = 1; // default to Authentic Bottle from LWIN

        this.updateParentForm(data);

        this.setState({ results: [], query: `${item.wine} ${item.producer} ${bottleSize ? bottleSize.description : ''}`, data });
    };

    startLedgerCreation = async () => {
        const { showNotification } = this.props;
        const { caseQRCode, caseRFIDCode } = this.state.data;
        let error = "";
        if (!this.state.data.lwinCode) {
            error = "Please use search feature and select a wine from the list";
        } else if (this.state.bottleEntryMode === 'single') {
            await this.saveLedger();
            window.location.href = '/#/bottleInformation';
        } else if (caseRFIDCode && await bottleCaseRfidCodeExists(caseRFIDCode)) {
            error = `The RFID code '${caseRFIDCode}' already exists`;
        } else if (caseQRCode && await bottleCaseQrCodeExists(caseQRCode)) {
            error = `The QR code '${caseQRCode}' already exists`;
        } else {
            const { caseId } = this.state;
            const numberOfBottles = parseInt(this.state.data.caseNumberOfBottles);
            if (caseId && !/^[1-9a-f]{24}/i.test(caseId)) {
                error = `The case ID '${caseId}' is invalid.` +
                    `It must be a 24 digit hex code or empty`;
            } else if (numberOfBottles < 1 || numberOfBottles > MAX_NUM_BOTTLES) {
                error = `The number of bottles must be between 1 and ${MAX_NUM_BOTTLES}.`;
            } else {
                window.location.href = '/#/multiBottleEntry';
            }
        }
        if (error) {
            showNotification(error);
        }
    };

    saveLedger = async () => {
        if (!this.state.data.lwinCode) {
            this.props.showNotification("Please use search feature and select a wine from the list", 'warning', { autoHideDuration: 10000 });
            return false;
        }

        try {
            const ledger = await newLedger(this.state.data);
            this.props.showNotification("Ledger Created Succesfully", 'warning', { autoHideDuration: 10000 });
            this.props.handleSelectLedger(ledger);

            window.location.href = '/#/bottleInformation';
        } catch (err) {
            this.props.showNotification(err.error, 'warning', { autoHideDuration: 10000 });
        }
    };

    handleNewWine = async () => {
        try {
            this.setLoadingState(true);
            const { newWineProducer, newWineName, newWineCountry, newWineCountryDesc, newWineRegion,
                newWineSubregion, newWineColor, newWineType, newWineDesignation, newWineClassification,
                newWineClassificationDesc, newWineAppellation, newWineBottleSize, newWineVarietal } = this.state.data;
            const { formName } = this.state;

            const validCountry = (newWineCountry && newWineCountry > 0 && newWineCountry !== 18) ||
                (newWineCountry && newWineCountry > 0 && newWineCountry === 18 &&
                    newWineCountryDesc && newWineCountryDesc.length > 0);

            const validFields = newWineProducer && newWineProducer.length > 0 && newWineName && newWineName.length > 0 &&
                newWineRegion && newWineRegion.length > 0 &&
                newWineSubregion && newWineSubregion.length > 0 && newWineDesignation && newWineDesignation.length > 0 &&
                newWineAppellation && newWineAppellation.length > 0 && newWineVarietal && newWineVarietal.length > 0;

            const validClassification = (newWineClassification && newWineClassification > 0 && newWineClassification !== 12) ||
                (newWineClassification && newWineClassification > 0 && newWineClassification === 12 &&
                    newWineClassificationDesc && newWineClassificationDesc.length > 0);

            const validOptions = newWineType && newWineType > 0 && newWineColor && newWineColor > 0 &&
                newWineBottleSize && newWineBottleSize > 0;

            if (validFields && validCountry && validClassification && validOptions) {
                const data = {
                    producer: newWineProducer,
                    wine: newWineName,
                    country: (this.state.options[formName]['country'] || [])
                        .find(c => c.id === newWineCountry).description || 'Other',
                    countryDescription: newWineCountryDesc || '',
                    region: newWineRegion,
                    subRegion: newWineSubregion,
                    colour: (this.state.options[formName]['wineColor'] || [])
                        .find(c => c.id === newWineColor).description,
                    type: (this.state.options[formName]['wineType'] || [])
                        .find(c => c.id === newWineType).description,
                    designation: newWineDesignation,

                    classification: (this.state.options[formName]['classification'] || []).find(c => c.id == newWineClassification).description || 'Other',
                    classificationDescription: newWineClassificationDesc || '',

                    appellation: newWineAppellation,
                    bottleSize: newWineBottleSize,
                    varietal: newWineVarietal
                };

                const result = await addWine(data);
                if (result && result.wine) {
                    this.setLoadingState(false);
                    this.props.showNotification("New wine added successfully");
                    this.onClose();
                }
            } else {
                this.setLoadingState(false);
                this.props.showNotification("All fields are mandatory");
            }
        } catch (error) {
            this.setLoadingState(false);
            chaiErrorHandler(this.notify).catch(
                error.content
                    ? error.content.message
                    : 'An error occurred adding a new wine. Please contact an Administrator.'
            );
        }
    };

    handleLocalChange = (e, field) => {
        const data = { ...this.state.data };
        data[field] = e.target.value;
        this.setState({ data });
    };

    handleChangeBottleEntryMode = evt => this.setState({ bottleEntryMode: evt.target.value });

    onClose = () => {
        const data = this.state.data;

        data.newWineProducer = '';
        data.newWineName = '';
        data.newWineCountry = 0;
        data.newWineCountryDesc = '';
        data.newWineRegion = '';
        data.newWineSubregion = '';
        data.newWineColor = 0;
        data.newWineType = 0;
        data.newWineDesignation = '';
        data.newWineClassification = 0;
        data.newWineClassificationDesc = '';
        data.newWineAppellation = '';
        data.newWineBottleSize = 0;
        data.newWineVarietal = '';
        data.typeOfInput = 0;

        this.setState({ wineModal: false, data });
    };

    renderWineModal = () => {
        const formName = formNames.BOTTLEINFORMATION;
        return (
            <Dialog
                open={this.state.wineModal}
                onClose={this.onClose}
                aria-labelledby="form-dialog-title"
                maxWidth={"sm"}
                fullWidth
            >
                <DialogContent>
                    <ChaiBodyContainer >
                        <ChaiRowBodyContainer>
                            <ChaiTextField
                                value={this.state.data.newWineProducer}
                                onChange={(e) => this.handleLocalChange(e, 'newWineProducer')}
                                label="PRODUCER*" style={{ width: '25%' }}
                            />
                            <ChaiTextField
                                value={this.state.data.newWineName}
                                onChange={(e) => this.handleLocalChange(e, 'newWineName')}
                                label="WINE NAME*" style={{ width: '25%' }}
                            />
                            <ChaiDropDown
                                value={this.state.data.newWineCountry}
                                onChange={(e) => this.handleLocalChange(e, 'newWineCountry')}
                                label="COUNTRY*" style={{ width: '25%' }}
                                items={this.state.options[formName]['country'] || []}
                            />
                            {
                                this.state.data.newWineCountry === 18 ?
                                    <ChaiTextField
                                        value={this.state.data.newWineCountryDesc}
                                        onChange={(e) => this.handleLocalChange(e, 'newWineCountryDesc')}
                                        label="DESCRIPTION*"
                                        style={{ width: '25%' }}
                                    /> : <div style={{ width: '25%' }}></div>
                            }
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer>
                            <ChaiTextField
                                value={this.state.data.newWineRegion}
                                onChange={(e) => this.handleLocalChange(e, 'newWineRegion')}
                                label="REGION*" style={{ width: '25%' }}
                            />
                            <ChaiTextField
                                value={this.state.data.newWineSubregion}
                                onChange={(e) => this.handleLocalChange(e, 'newWineSubregion')}
                                label="SUB REGION*" style={{ width: '25%' }}
                            />
                            <ChaiDropDown
                                value={this.state.data.newWineBottleSize}
                                onChange={(e) => this.handleLocalChange(e, 'newWineBottleSize')}
                                label="BOTTLE SIZE*"
                                style={{ width: '25%' }}
                                items={this.state.options[formNames.BOTTLEINFORMATION]['bottleSize'] || []}
                            />
                            <div style={{ width: '25%' }}></div>
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer>
                            <ChaiTextField
                                value={this.state.data.newWineAppellation}
                                onChange={(e) => this.handleLocalChange(e, 'newWineAppellation')}
                                label="APPELLATION*" style={{ width: '25%' }}
                            />
                            <ChaiTextField
                                value={this.state.data.newWineVarietal}
                                onChange={(e) => this.handleLocalChange(e, 'newWineVarietal')}
                                label="VARIETAL/BLEND*" style={{ width: '25%' }}
                            />
                            <ChaiDropDown
                                value={this.state.data.newWineColor}
                                onChange={(e) => this.handleLocalChange(e, 'newWineColor')}
                                label="WINE COLOR*"
                                style={{ width: '25%' }}
                                items={this.state.options[formNames.BOTTLEINFORMATION]['wineColor'] || []}
                            />
                            <ChaiDropDown
                                value={this.state.data.newWineType}
                                onChange={(e) => this.handleLocalChange(e, 'newWineType')}
                                label="WINE TYPE*"
                                style={{ width: '25%' }}
                                items={this.state.options[formName]['wineType'] || []}
                            />
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer>
                            <ChaiTextField
                                value={this.state.data.newWineDesignation}
                                onChange={(e) => this.handleLocalChange(e, 'newWineDesignation')}
                                label="DESIGNATION*" style={{ width: '25%' }}
                            />
                            <ChaiDropDown
                                value={this.state.data.newWineClassification}
                                onChange={(e) => this.handleLocalChange(e, 'newWineClassification')}
                                label="CLASSIFICATION*"
                                style={{ width: '25%' }}
                                items={this.state.options[formName]['classification'] || []}
                            />
                            {
                                this.state.data.newWineClassification === 12 ?
                                    <ChaiTextField
                                        value={this.state.data.newWineClassificationDesc}
                                        onChange={(e) => this.handleLocalChange(e, 'newWineClassificationDesc')}
                                        label="DESCRIPTION*"
                                        style={{ width: '25%' }}
                                    /> : <div style={{ width: '25%' }}></div>
                            }
                            <div style={{ width: '25%' }}></div>
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer>
                            <div style={{ width: '65%' }}></div>
                            <ChaiHeaderButton label="Cancel"
                                onClick={this.onClose}
                                style={{ width: '15%', marginTop: "20px", marginLeft: "20px" }}
                            />
                            <div style={{ width: '5%' }}></div>
                            <ChaiHeaderButton label="Confirm"
                                onClick={this.handleNewWine}
                                style={{ width: '15%', marginTop: "20px" }}
                            />
                        </ChaiRowBodyContainer>
                    </ChaiBodyContainer >
                </DialogContent>
            </Dialog>
        )
    };

    render() {
        const {
            bottleEntryMode,
            query,
            results
        } = this.state;
        const { classes } = this.props;
        const footerData = {
            message: '',
            buttonLabel: 'Next',
            loading: false
        };
        const searchFields = ['wine', 'country', 'producer', 'vintage'];
        const multipleBottleEntryClasses = classNames(
            classes.filter,
            classes.multipleBottleEntryFields,
            bottleEntryMode === 'multiBottleEntry' ? '' : classes.hide
        );

        return (
            <Fragment>
                <ChaiBodyContainer>
                    {this.renderWineModal()}
                    <div style={{ margin: '0 auto', maxWidth: '707px', width: '100%' }}>
                        <ChaiRowBodyContainer style={{ minWidth: '390px'}}>
                            <ChaiAutoComplete
                                label={"WINE SEARCH"}
                                style={{ width: '100%' }}
                                disabled={!this.calculateWritePermissionForms('wineName')}
                                data={results}
                                fields={searchFields}
                                value={query}
                                onSelect={this.handleSearchSelect}
                                onChange={this.handleSearchChange}
                            />
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer
                            style={{ marginLeft: '16px', marginTop: '-48px' }}>
                            <div>
                                <ChaiRadioButton
                                    label='Single Bottle Entry'
                                    checked={bottleEntryMode === 'single'}
                                    value='single'
                                    name='bottleEntryMode'
                                    disabled={!this.calculateWritePermissionForms('wineName')}
                                    onChange={this.handleChangeBottleEntryMode}
                                >
                                </ChaiRadioButton>
                                <ChaiRadioButton
                                    label='Multiple Bottle Entry'
                                    checked={bottleEntryMode === 'multiBottleEntry'}
                                    value='multiBottleEntry'
                                    name='bottleEntryMode'
                                    disabled={!this.calculateWritePermissionForms('wineName')}
                                    onChange={this.handleChangeBottleEntryMode}
                                >
                                </ChaiRadioButton>
                            </div>
                        </ChaiRowBodyContainer>
                        <ChaiRowBodyContainer style={{ marginTop: '-32px' }}>
                            <div className={multipleBottleEntryClasses} style={{ width: '100%' }}>
                                <div style={{ display: 'flex' }}>
                                    <ChaiTextField
                                        {...this.getField('caseId')}
                                        label="Case ID"
                                    ></ChaiTextField>
                                    <ChaiTextField
                                        {...this.getField('caseNumberOfBottles')}
                                        label="Number of bottles"
                                        type="number"
                                        numberDomain="Z+"
                                    ></ChaiTextField>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <ChaiTextField
                                        {...this.getField('caseRFIDCode')}
                                        label="Case RFID"
                                        style={{ minWidth: '120px' }}
                                    ></ChaiTextField>
                                    <ChaiTextField
                                        {...this.getField('caseQRCode')}
                                        label="Case QR Code"
                                        style={{ minWidth: '120px' }}
                                    ></ChaiTextField>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <ChaiDropDown
                                        {...this.getField('caseInformation')}
                                        label="Case information"
                                        items={this.state.caseInformationOptions}
                                        style={{ flexGrow: 1}}
                                    ></ChaiDropDown>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <ChaiImageInput
                                        {...this.getFileField('caseImage')}
                                        label="Case Image"
                                    />
                                </div>
                            </div>
                        </ChaiRowBodyContainer>
                    </div>
                </ChaiBodyContainer>
                <ChaiFooterContainer
                    {...footerData}
                    showInfo={false}
                    onClick={this.startLedgerCreation}
                />
            </Fragment>
        );
    }
}

ChaiSearchScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiSearchScreen);
