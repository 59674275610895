import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import ChaiTextField from '../ChaiTextField';
import ChaiHeaderButton from '../ChaiHeaderButton';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiSwitch from '../ChaiSwitch';
import chaiErrorHandler from '../../Handler/chaiErrorHandler';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { updateUserData } from './service'
import ChaiBodyContainer from "../ChaiBodyContainer";
import DialogTitle from '@material-ui/core/DialogTitle';
import ChaiDropDown from '../ChaiDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import { ledgerVisibilityOptions } from './service';
const isTablet = window.innerWidth <= 1024;

const styles = theme => ({ title: { fontSize: isTablet ? '1.2em' : '1.8em', width: '100%', textAlign: 'center', color: '#3f3e3e', fontFamily: '"Source Sans Pro", sans-serif', fontWeight: 600, display: 'flex', flexDirection: 'column', textTransform: 'uppercase' } });
const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        maxWidth: 'unset',
        backgroundColor: '#000',
        color: '#fff',
        fontFamily: '"Source Sans Pro", sans-serif',
        fontWeight: 600,
        minWidth: 300,
        fontSize: '16px',
        lineHeight: '18px',
    },
}))(Tooltip);
var strongRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/);

class ChaiUserSettings extends React.Component {

    constructor(props) {
        super(props);
        const user = JSON.parse(localStorage.getItem('user'));
        this.state = {
            name: user.name,
            registrationEmail: user.email,
            contactEmail: user.contactEmail,
            contactPhone: user.contactPhone,
            country: user.country,
            handle: user.handle,
            state: user.state,
            city: user.city,
            localAddress: user.localAddress,
            password: "",
            newPassword: "",
            confirmationPassword: "",
            searchable: user.searchable !== undefined ? user.searchable : true, // Default value for existent users until modified
            ledgersVisibilityPublic: user.ledgersVisibilityPublic !== undefined ? (user.ledgersVisibilityPublic ? 1 : 0) : 0
        };
    }

    updateLocalStorage = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        user.contactEmail = this.state.contactEmail;
        user.contactPhone = this.state.contactPhone;
        user.country = this.state.country;
        user.state = this.state.state;
        user.city = this.state.city;
        user.handle = this.state.handle;
        user.localAddress = this.state.localAddress;
        user.searchable = this.state.searchable;
        user.ledgersVisibilityPublic = this.state.ledgersVisibilityPublic;
        localStorage.setItem('user', JSON.stringify(user));
    }

    submit = () => {
        if (this.state.password.length > 0) {
            this.save();
        } else {
            this.setState({ modal: true });
        }
    }

    save = async () => {
        const { password, newPassword, confirmationPassword } = this.state;
        const { showNotification } = this.props;
        try {
            if (password.length > 0) {
                const validFormat = newPassword.length > 0 ? strongRegex.test(newPassword) : true;
                const matchingPasswords = newPassword === confirmationPassword;

                if (validFormat && matchingPasswords) {
                    const result = await updateUserData({ ...this.state });
                    showNotification("User information updated Succesfully");
                    this.updateLocalStorage();
                    this.setState({ modal: false });
                } else {
                    let message = '';

                    if (!validFormat) {
                        message = `The password must contain at least 1 Lowercase Alphabetical character \n
                        The password must contain at least 1 Uppercase Alphabetical character \n 
                        The password must contain at least 1 Numeric character \n
                        The password must contain at least 1 Special character \n
                        The password must be eight characters or longer \n`;
                    }
                    if (!matchingPasswords) {
                        message += `Passwords does not match`;
                    }

                    const newText = message.indexOf('\n') > -1 ? 
                        message.split('\n').map(i => <p>{i}</p>) : 
                        message;
                    showNotification(newText);
                }
            } else {
                showNotification("Before Save Please Enter Your Current Password");
            }
            this.setState();
        } catch (error) {
            console.log(error)
            const msg = error.content ? error.content.Message : "Unexpected Error";
            chaiErrorHandler(this.notify).catch(msg);
        }
    }

    handleChange = (e, field) => {
        const data = { ...this.state };
        data[field] = e.target.value;
        this.setState({ ...data });
    };

    getField = (field) => {
        return ({
            value: this.state[field],
            onChange: (e) => this.handleChange(e, field),
        });
    };

    getBooleanField = (field) => {
        return {
            value: this.state[field],
            onChange: (e) => this.handleToogle(e, field),
            keyField: field
        }
    }

    handleToogle = (e, field) => {
        const data = { ...this.state };
        data[field] = !data[field];
        this.setState({ ...data });
    };

    notify = message => {
        this.props.showNotification(message);
    }

    closeModal = () => this.setState({ modal: false });


    renderPasswordModal = () => {
        const { modal } = this.state;
        return (
            <Dialog
                open={modal}
                onClose={this.closeModal}
                aria-labelledby="form-dialog-title"
                maxWidth={"sm"}
                fullWidth
            >
                <DialogTitle id="form-dialog-title">Password Confirmation</DialogTitle>
                <DialogContent>
                    <ChaiTextField
                        {...this.getField('password')}
                        label={'Current Password'}
                        style={{ width: '100%' }}
                        type="password"
                    />
                </DialogContent>
                <DialogActions style={{ marginRight: 25 }}>
                    <ChaiHeaderButton label="Submit" onClick={() => this.save()} />
                </DialogActions>
            </Dialog>
        )
    }

    render() {
        return (
            <ChaiBodyContainer >
                {this.renderPasswordModal()}
                <ChaiRowBodyContainer>
                    <h1 className={this.props.classes.title}>User Settings</h1>
                </ChaiRowBodyContainer>
                <ChaiRowBodyContainer>
                    <ChaiTextField
                        {...this.getField('name')}
                        label={'Name'}
                        style={{ width: '25%' }}
                        disabled={true}
                    />
                    <ChaiTextField
                        {...this.getField('registrationEmail')}
                        label={'Email'}
                        style={{ width: '25%' }}
                        disabled={true}
                    />
                    <ChaiTextField
                        {...this.getField('contactEmail')}
                        label={'Contact Email'}
                        style={{ width: '25%' }}
                    />
                    <ChaiTextField
                        {...this.getField('contactPhone')}
                        label={'Contact Phone'}
                        style={{ width: '25%' }}
                    />
                </ChaiRowBodyContainer>
                <ChaiRowBodyContainer>
                    <ChaiTextField
                        {...this.getField('country')}
                        label={'Country'}
                        style={{ width: '20%' }}
                    />

                    <ChaiTextField
                        {...this.getField('state')}
                        label={'State'}
                        style={{ width: '20%' }}
                    />
                    <ChaiTextField
                        {...this.getField('city')}
                        label={'City'}
                        style={{ width: '20%' }}
                    />
                    <ChaiTextField
                        {...this.getField('localAddress')}
                        label={'Address'}
                        style={{ width: '20%' }}
                    />

                    <ChaiTextField
                        {...this.getField('handle')}
                        label={'Handle'}
                        style={{ width: '20%' }}
                    />
                </ChaiRowBodyContainer>
                <ChaiRowBodyContainer>
                    <ChaiTextField
                        {...this.getField('password')}
                        label={'Current Password'}
                        style={{ width: '25%' }}
                        type="password"
                    />
                    <ChaiTextField
                        {...this.getField('newPassword')}
                        label={'New Password'}
                        style={{ width: '25%' }}
                        type="password"
                    />
                    <ChaiTextField
                        {...this.getField('confirmationPassword')}
                        label={'Confirm New Password'}
                        style={{ width: '25%' }}
                        type="password"
                    />
                </ChaiRowBodyContainer>
                <ChaiRowBodyContainer>
                    <ChaiSwitch
                        label='Allow other users to find me'
                        {...this.getBooleanField('searchable')}
                        style={{ width: '30%', padding: '20px' }}
                    />
                    <ChaiDropDown
                        label={
                            <Fragment>
                                <label>Ledger Visibility</label>&nbsp;&nbsp;&nbsp;
                                <HtmlTooltip
                                    placement="right-end"
                                    title={
                                        <Fragment>
                                            When you receive a new ledger, the certificate will become automatically private/public, <br />
                                            depending on your choice here.
                                        </Fragment>
                                    }
                                >
                                    <HelpIcon style={{ fontSize: '20px' }} />
                                </HtmlTooltip>
                            </Fragment>
                        }
                        {...this.getField('ledgersVisibilityPublic')}
                        style={{ width: '30%', padding: '20px' }}
                        items={ledgerVisibilityOptions}
                    />
                    <div style={{ width: '30%' }}></div>
                </ChaiRowBodyContainer>
                <ChaiRowBodyContainer>
                    <ChaiHeaderButton label="Save Changes"
                        onClick={this.submit}
                        style={{ width: '20%', marginTop: "20px" }}
                    />
                </ChaiRowBodyContainer>
            </ChaiBodyContainer >
        )
    }
}

ChaiUserSettings.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ChaiUserSettings);
