import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import { CardContent, CircularProgress } from '@material-ui/core';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiBodyContainer from '../ChaiBodyContainer';
import ChaiHeaderButton from '../ChaiHeaderButton';

import { getLedgersForCsvExport } from '../ChaiLedger/ledger';
import { exportCsvHeaders } from './service'
import { screenOptions } from '../ChaiUploadSection/service';
import { CSVLink } from "react-csv";

const styles = theme => ({
    card: { marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#efefef' },
    log: {
        width: '100%', marginBottom: 10, paddingBottom: 10, borderBottom: '1px solid #3e3e3e',
        fontFamily: '"Source Sans Code", sans-serif', fontSize: '1em', color: '#3e3e3e'
    }
});

const messages = ['Still processing your data...', 'Working with ledgers...', 'This may take a while...', 'Will finish soon...'];
let interval;

class ChaiLedgerExport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: null,
            message: '',
            downloaded: false,
            downloading: false,
            open: false,
            processed: false
        }
    }

    export = async () => {
        try {
            const { downloaded, processed } = this.state;
            const { isUploading } = this.props;

            if (!downloaded && processed) {
                this.setState({ open: true });
                return;
            };

            isUploading(true);
            this.setState({ uploading: true, message: 'Export process begun...' });

            interval = setInterval(() => {
                const currentMessage = messages[Math.floor(Math.random() * 4) + 1];
                this.setState({ message: currentMessage });
            }, 1 * 5000);

            const ledgers = await getLedgersForCsvExport();

            const data = await this.parseResponse(ledgers);
            this.setState({ data, processed: true, uploading: false, message: '' });

            isUploading(false);
            clearInterval(interval);
        } catch (error) {
            this.props.isUploading(false);
            this.setState({
                uploading: false,
                processed: false,
                downloaded: false,
                message: error || 'Export process interrupted due to an error. Please, try again later or contact your Administrator.'
            });
            clearInterval(interval);
        }
    };

    parseResponse = async (ledgers) => {
        if (ledgers && ledgers.length > 0) {
            let parsedData = [];
            let options = await screenOptions();

            ledgers.map((ledger) => {
                try {

                    const bottleSizeFilter = options.bottleInformation.bottleSizeOptions.filter(x => x.id === ledger.bottleSize)[0];
                    const bottleSize = bottleSizeFilter ? bottleSizeFilter.description : `${ledger.bottleSize} not recognize`;

                    const wineColorFilter = options.bottleInformation.wineColorOptions.filter(x => x.id === ledger.wineColor)[0];
                    const wineColor = wineColorFilter ? wineColorFilter.description : `${ledger.wineColor} not recognize`;

                    const wineTypeFilter = options.bottleInformation.wineTypeOptions.filter(x => x.id === ledger.wineType)[0];
                    const wineType = wineTypeFilter ? wineTypeFilter.description : `${ledger.wineType} not recognize`;

                    const countryFilter = options.bottleInformation.countryOptions.filter(x => x.id === ledger.country)[0];
                    const country = countryFilter ? countryFilter.description : `${ledger.country} not recognize`;

                    const classificationFilter = options.bottleInformation.classificationOptions.filter(x => x.id === ledger.classification)[0];
                    const classification = classificationFilter ? classificationFilter.description : `${ledger.classification} not recognize`;

                    const bottleByAtFilter = options.bottleInformation.bottledByAtOptions.filter(x => x.id === ledger.bottledByAt)[0];
                    const bottleByAt = bottleByAtFilter ? bottleByAtFilter.description : `${ledger.bottleByAt} not recognize`;

                    ledger.typeOfInput = (ledger.typeOfInput && ledger.typeOfInput > 0) ? ledger.typeOfInput === 1 ? "Authentic" : "Counterfeit" : "";
                    ledger.bottleSize = (ledger.bottleSize && ledger.bottleSize > 0) ? bottleSize || "" : "";
                    ledger.wineColor = (ledger.wineColor && ledger.wineColor > 0) ? wineColor || "" : "";
                    ledger.wineType = (ledger.wineType && ledger.wineType > 0) ? wineType || "" : "";
                    ledger.country = (ledger.country && ledger.country > 0) ? country || "" : "";
                    ledger.classification = (ledger.classification && ledger.classification > 0) ? classification || "" : "";
                    ledger.bottledByAt = (ledger.bottledByAt && ledger.bottledByAt > 0) ? bottleByAt || "" : "";

                    // Booleans
                    ledger.isVintageLegible = ledger.isVintageLegible === false ? "false" : "true";
                    ledger.puntAirVent = ledger.puntAirVent === false ? "false" : "true";
                    ledger.producerBackLabel = ledger.producerBackLabel === false ? "false" : "true";
                    ledger.healthWarningLabel = ledger.healthWarningLabel === false ? "false" : "true";

                    const labelConditionFilter = options.bottleData.conditionOptions.filter(x => x.id === ledger.labelConditionForAge)[0];
                    const labelCondition = labelConditionFilter ? labelConditionFilter.description : `${ledger.labelConditionForAge} not recognize`;

                    const corkConditionFilter = options.bottleData.corkConditionOptions.filter(x => x.id === ledger.corkCondition)[0];
                    const corkCondition = corkConditionFilter ? corkConditionFilter.description : `${ledger.corkCondition} not recognize`;

                    const capsuleConditionFilter = options.bottleData.conditionOptions.filter(x => x.id === ledger.capsuleCondition)[0];
                    const capsuleCondition = capsuleConditionFilter ? capsuleConditionFilter.description : `${ledger.capsuleCondition} not recognize`;

                    const capsuleConditionDetails = ledger.capsuleConditionDetails && ledger.capsuleConditionDetails.length ? ledger.capsuleConditionDetails.map((x) => {
                        let filter = options.bottleData.otherCapsuleOptions.filter(v => v.id === x)[0];
                        return filter ? filter.description : `${x} not recognize`;
                    }) : '-';
                    const sediments = ledger.sediments && ledger.sediments.length ? ledger.sediments.map((x) => {
                        let filter = options.bottleData.sedimentOptions.filter(v => v.id === x)[0];
                        return filter ? filter.description : `${x} not recognize`;
                    }) : '-';

                    ledger.fillLevel = (ledger.fillLevel && ledger.fillLevel > 0) ? ledger.fillLevel === 1 ? "Shouldered Bottle" : "Burgundy or Hock Bottle" : "";
                    ledger.labelConditionForAge = (ledger.labelConditionForAge && ledger.labelConditionForAge > 0) ? labelCondition || "" : "";
                    ledger.corkCondition = (ledger.corkCondition && ledger.corkCondition > 0) ? corkCondition || "" : "";
                    ledger.capsuleCondition = (ledger.capsuleCondition && ledger.capsuleCondition > 0) ? capsuleCondition || "" : "";
                    ledger.capsuleConditionDetails = (ledger.capsuleConditionDetails && ledger.capsuleConditionDetails.length) ? capsuleConditionDetails || "" : "";
                    ledger.sediments = (ledger.sediments && ledger.sediments.length) ? sediments || "" : "";

                    const ribbingFilter = options.mainLabel.ribbingOptions.filter(x => x.id === ledger.ribbing)[0];
                    const ribbing = ribbingFilter ? ribbingFilter.description : `${ledger.ribbing} not recognize`;

                    const labelTypeFilter = options.mainLabel.labelTypeOptions.filter(x => x.id === ledger.labelType)[0];
                    const labelType = labelTypeFilter ? labelTypeFilter.description : `${ledger.labelType} not recognize`;

                    const paperFinishFilter = options.mainLabel.paperFinishOptions.filter(x => x.id === ledger.paperFinish)[0];
                    const paperFinish = paperFinishFilter ? paperFinishFilter.description : `${ledger.paperFinish} not recognize`;

                    const paperColorFilter = options.mainLabel.paperColorOptions.filter(x => x.id === ledger.paperColor)[0];
                    const paperColor = paperColorFilter ? paperColorFilter.description : `${ledger.paperColor} not recognize`;

                    const labelPrintMethods = ledger.labelPrintMethod && ledger.labelPrintMethod.length ? ledger.labelPrintMethod.map((x) => {
                        let filter = options.mainLabel.labelPrintMethodOptions.filter(v => v.id === x)[0];
                        return filter ? filter.description : `${x} not recognize`;
                    }) : '-';
                    const inkColors = ledger.inkColor && ledger.inkColor.length ? ledger.inkColor.map((x) => {
                        let filter = options.mainLabel.inkColorsOptions.filter(v => v.id === x)[0];
                        return filter ? filter.description : `${x} not recognize`;
                    }) : '-';

                    ledger.ribbing = (ledger.ribbing && ledger.ribbing != null) ? ribbing || "" : "";
                    ledger.labelType = (ledger.labelType && ledger.labelType != null) ? labelType || "" : "";
                    ledger.paperFinish = (ledger.paperFinish && ledger.paperFinish != null) ? paperFinish || "" : "";
                    ledger.paperColor = (ledger.paperColor && ledger.paperColor != null) ? paperColor || "" : "";
                    ledger.labelPrintMethod = (ledger.labelPrintMethod && ledger.labelPrintMethod != null) ? labelPrintMethods || "" : "";
                    ledger.labelFoilingOption = (ledger.labelFoilingOption && ledger.labelFoilingOption > 0) ? (ledger.labelFoilingOption === 1 ? "Gold" : "Silver") : "";
                    ledger.inkColor = (ledger.inkColor && ledger.inkColor.length) ? inkColors || "" : "";

                    const appliedByFilter = options.closure.capsuleApplier.filter(x => x.id === ledger.capsuleAppliedBy)[0];
                    const appliedBy = appliedByFilter ? appliedByFilter.description : `${ledger.capsuleAppliedBy} not recognize`;

                    const capsuleMaterialFilter = options.closure.capsuleMaterial.filter(x => x.id === ledger.capsuleMaterial)[0];
                    const capsuleMaterial = capsuleMaterialFilter ? capsuleMaterialFilter.description : `${ledger.capsuleMaterial} not recognize`;

                    const capsuleColors = ledger.capsuleColor && ledger.capsuleColor ? ledger.capsuleColor.map((x) => {
                        let filter = options.closure.capsuleColor.filter(v => v.id === x)[0];
                        return filter ? filter.description : `${x} not recognize`;
                    }) : '-';

                    ledger.capsuleAppliedBy = (ledger.capsuleAppliedBy && ledger.capsuleAppliedBy > 0) ? appliedBy || "" : "";
                    ledger.capsuleMaterial = (ledger.capsuleMaterial && ledger.capsuleMaterial > 0) ? capsuleMaterial || "" : "";
                    ledger.capsuleColor = (ledger.capsuleColor && ledger.capsuleColor.length) ? capsuleColors || "" : "";

                    const glassColorFilter = options.glass.colorOptions.filter(x => x.id === ledger.glassColor)[0];
                    const glassColor = glassColorFilter ? glassColorFilter.description : `${ledger.glassColor} not recognize`;

                    const textureFilter = options.glass.textureOptions.filter(x => x.id === ledger.puntTexture)[0];
                    const texture = textureFilter ? textureFilter.description : `${ledger.puntTexture} not recognize`;

                    const puntFilter = options.glass.baseOptions.filter(x => x.id === ledger.puntBase)[0];
                    const punt = puntFilter ? puntFilter.description : `${ledger.puntBase} not recognize`;

                    const depthFilter = options.glass.depthOptions.filter(x => x.id === ledger.puntDepth)[0];
                    const depth = depthFilter ? depthFilter.description : `${ledger.puntDepth} not recognize`;

                    const productionMethodFilter = options.glass.methodOptions.filter(x => x.id === ledger.glassProduction)[0];
                    const productionMethod = productionMethodFilter ? productionMethodFilter.description : `${ledger.glassProduction} not recognize`;

                    const shapeFilter = options.glass.shapeOptions.filter(x => x.id === ledger.kickShape)[0]
                    const shape = shapeFilter ? shapeFilter.description : `${ledger.kickShape} not recognize`;

                    ledger.glassColor = (ledger.glassColor && ledger.glassColor > 0) ? glassColor || "" : "";
                    ledger.puntTexture = (ledger.puntTexture && ledger.puntTexture > 0) ? texture || "" : "";
                    ledger.puntBase = (ledger.puntBase && ledger.puntBase > 0) ? punt || "" : "";
                    ledger.puntDepth = (ledger.puntDepth && ledger.puntDepth > 0) ? depth || "" : "";
                    ledger.glassProduction = (ledger.glassProduction && ledger.glassProduction > 0) ? productionMethod || "" : "";
                    ledger.kickShape = (ledger.kickShape && ledger.kickShape > 0) ? shape || "" : "";

                    parsedData = [...parsedData, ledger];
                } catch (error) {
                    console.error('ERROR PARSING LEDGER', ledger, error);
                }
            });

            return parsedData;
        }

        return [];
    };

    render() {
        const { classes } = this.props;
        const { uploading, message, processed, data } = this.state;

        return (
            <Card className={classes.card}>
                <CardContent>
                    <ChaiBodyContainer>
                        <div style={{ display: 'flex', justifyContent: 'unset', alignItems: 'center' }}>
                            <div style={{ width: 40 }} ></div>
                            {
                                uploading ? <CircularProgress /> :
                                    <ChaiHeaderButton
                                        onClick={this.export}
                                        disabled={processed}
                                        label={'EXPORT ALL WINES'}
                                        style={{ marginTop: 20 }} />
                            }
                            {
                                processed && <CSVLink style={{ padding: '10px 20px' }} filename={`CV-AdminAllLedgersExport-${(new Date().getTime())}.csv`} separator={';'} headers={exportCsvHeaders} data={data}>Download File</CSVLink>
                            }
                        </div>
                        <ChaiRowBodyContainer>
                            {message}
                        </ChaiRowBodyContainer>
                    </ChaiBodyContainer>
                </CardContent>
            </Card>
        )
    }
}

ChaiLedgerExport.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiLedgerExport);
