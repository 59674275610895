import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ChaiTable from '../ChaiTable';
import ChaiBaseForm from '../ChaiBaseForm';
import ChaiImageInput from '../ChaiImageInput';
import ChaiDropDown from '../ChaiDropDown';
import ChaiTextField from '../ChaiTextField';
import ChaiSwitch from '../ChaiSwitch';
import ChaiHeaderButton from '../ChaiHeaderButton';
import ChaiBodyContainer from '../ChaiBodyContainer';
import ChaiFooterContainer from '../ChaiFooterContainer';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiAutoComplete from '../ChaiAutoComplete';
import ChaiCheck from '../ChaiCheck';
import debounce from 'debounce';
import formNames from '../ChaiBaseForm/formNames';
import { getProvenance, saveProvenance, searchUsers, deleteFutureOwnershipTransfer } from './service';
import {
    provenanceTypesOptions,
    currencyTypesOptions,
    specialDatesOptions
} from './options';
import { getRole } from '../../Helpers/permissions';
import imageCompression from 'browser-image-compression';
import moment from 'moment';
const { uploadImage } = require('../../DataProviders/crudgeneric');

const styles = theme => ({
    searchIcon: {
        top: 'calc(50% - 12px)',
        right: '12px',
        color: 'rgba(0, 0, 0, 0.54)',
        position: 'absolute',
        pointerEvents: 'none'
    },
    checkBox: {
        fontSize: 34,
        marginTop: '.5em'
    },
    ownerPending: { color: 'gray' },
    ownerSelected: { color: 'green' }
});

let columnData = [
    { id: 'ownerName', label: 'Owner Name' },
    { id: 'ownerInfo', label: 'City, State/Province, Country' },
    { id: 'ownerAddress', label: 'Owner Contact Address' },
    { id: 'ownerEmail', label: 'Owner Contact Email' },
    { id: 'type', label: 'Type' },
    { id: 'bottleCost', label: 'Bottle Cost' },
    { id: 'currency', label: 'Currency' },
    { id: 'dateOfSale', label: 'Date Of Sale' },
    { id: 'actions', label: 'Actions' }
];

class ChaiProvenance extends ChaiBaseForm {
    constructor(props) {
        let formName = formNames.PROVENANCE;
        let emptyDataState = {
            provenanceDetails: []
        };

        super({ ...props, formName, emptyDataState });
        this.state = {
            ...this.state,
            currentOwner: null,
            currentInfo: null,
            currentAddress: null,
            currentEmail: null,
            certified: false,//ledger hast a trasnfer ownership uploaded to blockchain
            creating: false,
            editing: false,
            loading: false,
            modal: false,
            // Provenance Detail
            ownerId: null,
            ownerName: null,
            ownerInfo: null,
            ownerAddress: null,
            ownerEmail: null,
            updateOwnership: false,
            futureOwnershipTransfer: false,
            localID: null,
            provenanceType: null,
            bottleCost: null,
            lotCost: null,
            currency: 1,
            dateOfSale: null,
            proofOfPurchaseImage: null,
            vendor: null,
            auctionHouse: null,
            saleNumber: null,
            saleLot: null,
            consignmentDescription: null,
            consignmentDescriptionImage: null,
            purchasedFrom: null,
            encryptOwnerName: true,
            encryptOwnerInformation: true,
            creator: null,
            // Search user
            query: '',
            results: [],
            unassignedTransfer: false,
            deleteFuture: false,
            specialDate: false,
            specialDateOfSale: null,
            userRole: null
        };
    }

    getElement = async (ledger, version) => {
        const user = JSON.parse(localStorage.getItem('user'));
        const userRole = await getRole(user.id);

        this.setState({ userRole });
        await this.getOptionsWithFormName(formNames.PROVENANCE);

        // Other roles but Administrator can't see bottle cost and currency column detail
        if (this.state.userRole !== 'Administrator') {
            const indexBottleCost = columnData.findIndex(x => x.id === 'bottleCost');
            if (indexBottleCost > -1) columnData.splice(indexBottleCost, 1);

            const indexCurrency = columnData.findIndex(x => x.id === 'currency');
            if (indexCurrency > -1) columnData.splice(indexCurrency, 1);
        }

        return getProvenance(ledger.ledgerNumber, 0)
            .then(result => {
                const data = result;

                if (result) {
                    data.provenanceDetails = data.provenanceDetails.map((p, index) => {
                        const detail = { ...p };
                        detail.actions = this.getActions(p);

                        return detail;
                    });

                    data.updateOwnership = result.updateOwnership;

                    let currentDetails = { name: '', info: '', address: '', email: '' };
                    if (data.provenanceDetails.length) {
                        currentDetails = data.provenanceDetails[0].owner;
                    }
                    const currentOwner = this.props.currentLedger.owner || null;
                    if (!currentOwner.name || !currentOwner.userId) {
                        const updateLastOwner = {
                            currentOwner: currentDetails.name || 'N/A',
                            currentInfo: currentDetails.info || 'N/A',
                            currentAddress: currentDetails.address || 'N/A',
                            currentEmail: currentDetails.email || 'N/A'
                        };
                        const certified = data.provenanceDetails.find(det =>
                            (det.uploadedToBlockchain && det.updateOwnership)) ? true : false;
                        this.setState({ ...updateLastOwner, certified });
                    }
                }

                return result ?
                    data :
                    this.emptyDataState
            });
    };

    canEditUpdateOwnerShip = () => {
        return this.state.userRole === "Administrator" || !this.state.certified;
    }

    saveElement = async (ledger, version, element) => {
        if (this.state.deleteFuture) {
            const response = await deleteFutureOwnershipTransfer(ledger.ledgerNumber, version);
            if (response && response.ok) {
                this.props.showNotification('Future Ownership Transfer was deleted successfuly.');
            } else if (response && !response.ok) {
                this.props.showNotification('Future Ownership Transfer not found on Ledger.');
            } else {
                this.props.showNotification('An untracked error occurred while trying to delete Future Ownership Transfer.');
            }

            if (element && element.provenanceDetails.length === 0) return response;
        }

        return saveProvenance(ledger.ledgerNumber, version, element);
    };

    getActions = (item) => {
        const user = JSON.parse(localStorage.getItem('user'));
        return (<div> {
            // Can not modify the provenance if...
            // It was uploaded to blockchain
            (item.uploadedToBlockchain ||
                // It is a Collector
                this.state.userRole === 'Collector' ||
                // It is not the same person who created the provenance and it's not an Admin
                (this.state.userRole !== 'Administrator' &&
                    item.creator && item.creator !== user.id)) ?
                <Fragment>
                    <EditIcon
                        style={{ cursor: 'default', fontSize: 24, color: 'gray' }} />
                    <DeleteIcon
                        style={{ cursor: 'default', fontSize: 24, color: 'gray', paddingLeft: 30 }} />
                </Fragment>
                :
                <Fragment>
                    <EditIcon
                        style={{ cursor: 'pointer', fontSize: 24 }}
                        onClick={() => this.handleEditProvenance({ ...item })} />
                    <DeleteIcon
                        style={{ cursor: 'pointer', fontSize: 24, paddingLeft: 30 }}
                        onClick={() => this.handleDeleteProvenance({ ...item })} />
                </Fragment>
        } </div>);
    };

    getCleanUpDetail = () => {
        return {
            currentOwner: null,
            currentInfo: null,
            ownerId: null,
            ownerName: null,
            ownerInfo: null,
            ownerAddress: null,
            ownerEmail: null,
            provenanceType: null,
            bottleCost: null,
            lotCost: null,
            currency: null,
            dateOfSale: null,
            vendor: null,
            auctionHouse: null,
            saleNumber: null,
            saleLot: null,
            consignmentDescription: null,
            consignmentDescriptionImage: null,
            purchasedFrom: null,
            proofOfPurchaseImage: null,
            creating: false,
            editing: false,
            loading: false,
            encryptOwnerName: true,
            encryptOwnerInformation: true,
            specialDate: false,
            specialDateOfSale: null
        };
    };

    handleImageCompression = async (imageFile, uploadLimit) => {
        var options = {
            maxSizeMB: uploadLimit / 1024 / 1024
        };

        try {
            const compressedFile = await imageCompression(imageFile, options);
            return compressedFile;
        } catch (error) {
            console.log(error);
            this.setLoadingState(false);
            this.props.showNotification(`Something went wrong uploading the file`);
            return false;
        }
    };

    // For this particular case, we need to use upload images logic at the same level of ChaiProvenance
    onFileSelect = async (e, field) => {
        try {
            const uploadLimit = process.env.REACT_APP_CHAI_MAX_UPLOAD_SIZE || 2000000;
            var file = e.target.files[0];

            let maxSizeFlag = false;
            if (file.size && file.size > uploadLimit) {
                maxSizeFlag = true;
                var compressedFile = await this.handleImageCompression(file, uploadLimit);
                file = compressedFile;
            }

            const fileName = this.getShorterImageName(file.name);

            this.setLoadingState(true);
            const result = await uploadImage(file);
            this.setLoadingState(false);
            let data = { ...this.state };
            data[field] = result.Location; // Location provided by AWS S3 Bucket

            this.setState({ [`fileName${field}`]: fileName, ...data });

            if (maxSizeFlag) {
                this.props.showNotification(`The image you have uploaded exceeds the maximum allowed size. It has been compressed to fit.`);
            }
        } catch (error) {
            console.log(error);
            this.setLoadingState(false);
            this.props.showNotification(`Something went wrong uploading the file`);
        }
    };

    getBooleanField = (field) => {
        return {
            value: this.state[field],
            onChange: (e) => this.handleToogle(e, field),
            keyField: field
        }
    }

    handleToogle = (e, field) => {
        const data = { ...this.state };
        data[field] = !data[field];
        this.setState({ ...data });
    };

    getLocalField = (field, image) => {
        if (image) {
            let imagePieces = this.state[field] ? this.state[field].trim() ? this.state[field].split('/') : null : null;
            let fileNamePiece = imagePieces ? imagePieces[imagePieces.length - 1] : '';
            let filename = this.getShorterImageName(fileNamePiece);

            return {
                field,
                onChange: this.onFileSelect,
                fileName: filename,
                fileUrl: this.state[field],
                disabled: this.state.loading
            };
        }

        return {
            value: this.state[field],
            onChange: (e) => this.handleLocalChange(e, field),
            keyField: field,
            disabled: this.state.loading
        };
    };

    handleLocalChange = (e, field) => {
        const state = { ...this.state };
        state[field] = e.target.value;
        this.setState(state);
        if (field === 'query') {
            this.searchDelayed();
        }
    };

    handleEditProvenance = (detail) => {
        const copyDetail = { ...detail };
        const { data } = this.state;

        const type = provenanceTypesOptions.find(x => x.description === detail.type).id;
        const currency = detail.currency ?
            currencyTypesOptions.find(x => x.description === detail.currency).id : null;
        copyDetail.provenanceType = type;
        copyDetail.currency = currency;
        data.updateOwnership = copyDetail.updateOwnership;
        data.futureOwnershipTransfer = copyDetail.futureOwnershipTransfer;
        data.specialDateOfSale = copyDetail.specialDateOfSale || null;
        data.specialDate = copyDetail.specialDate;

        this.setState({ editing: true, ...data, ...copyDetail, query: '',
                        ownerName: copyDetail.ownerName, ownerId: copyDetail.ownerId });
    };

    handleNewProvenance = () => {
        const { data } = this.state;
        const user = JSON.parse(localStorage.getItem('user'));

        data.updateOwnership = false;
        data.ownerId = '';
        data.ownerName = '';
        data.ownerAddress = '';
        data.ownerEmail = '';
        data.specialDate = false;
        data.specialDateOfSale = null;
        data.creator = user.id;
        this.setState({ creating: !this.state.creating, data, query: '' });
    };

    handleCancelProvenanceDetail = () => {
        const cleanUp = this.getCleanUpDetail();

        this.setState({ ...cleanUp });
    };

    handleProvenanceDetail = () => {
        this.setLoadingState(true);
        const valid = this.isValid();
        const pendingOwnershipTransfer = this.state.data.provenanceDetails.find(x =>
            x.approved === false &&
            x.uploadedToBlockchain === false &&
            x.updateOwnership === true);
        if (pendingOwnershipTransfer && this.state.updateOwnership) {
            this.props.showNotification("This Ledger has one pending Ownership transfer")
            this.setLoadingState(false);
            return
        }
        if (valid.ok) {
            const data = this.state.data;
            const provenances = data.provenanceDetails;

            const type = provenanceTypesOptions.find(x => x.id === this.state.provenanceType).description;
            const currency = this.state.provenanceType !== 9 ? currencyTypesOptions.find(x => x.id === this.state.currency).description : null;

            const detail = {
                localID: this.state.localID,
                bottleCost: this.state.bottleCost,
                lotCost: this.state.lotCost,
                currency: currency,
                dateOfSale: this.state.dateOfSale,
                specialDate: this.state.specialDate,
                specialDateOfSale: this.state.specialDateOfSale || 0,
                type: type,
                ownerInfo: this.state.ownerInfo,
                ownerName: this.state.ownerName,
                ownerAddress: this.state.ownerAddress,
                ownerEmail: this.state.ownerEmail,
                ownerId: this.state.ownerId,
                proofOfPurchaseImage: this.state.proofOfPurchaseImage,
                updateOwnership: this.state.updateOwnership,
                futureOwnershipTransfer: this.state.futureOwnershipTransfer,
                lastEdited: new Date().toISOString().substring(0, 10),
                encryptOwnerName: this.state.encryptOwnerName,
                encryptOwnerInformation: this.state.encryptOwnerInformation,
            };

            switch (this.state.provenanceType) {
                case 1:
                case 5:
                    detail.vendor = this.state.vendor;
                    break;
                case 2:
                case 6:
                case 7:
                case 8:
                case 10:
                    detail.auctionHouse = this.state.auctionHouse;
                    detail.saleNumber = this.state.saleNumber;
                    detail.saleLot = this.state.saleLot;
                    detail.consignmentDescription = this.state.consignmentDescription;
                    detail.consignmentDescriptionImage = this.state.consignmentDescriptionImage;
                    break;
                case 3:
                case 4:
                    detail.purchasedFrom = this.state.purchasedFrom;
                    break;
                case 9:
                    detail.currency = null;
                    break;
                default:
                    break;
            }
            detail.updateOwnership = data.updateOwnership;

            const cleanUp = this.getCleanUpDetail();
            detail.actions = this.getActions(detail);

            if (this.state.editing) {
                const index = provenances.findIndex(x => x.localID === detail.localID);
                provenances[index] = detail;
            } else {
                provenances.push(detail);
            }

            data.provenanceDetails = provenances;

            this.setState({ data, ...cleanUp });
        } else {
            this.setLoadingState(false);
            this.props.showNotification(valid.error);
        }
    };

    handleDeleteProvenance = (item) => {
        // Delete future ownership transfer
        if (item.futureOwnershipTransfer === true) {
            this.openModal();
        } else {
            alert('Deleting!');
        }
    };

    isValid = () => {
        const bottleOwnerType = 9;
        const { bottleCost, currency, provenanceType, dateOfSale, ownerInfo, ownerName,
            vendor, auctionHouse, saleLot, saleNumber, purchasedFrom, ownerId, proofOfPurchaseImage,
            specialDate, specialDateOfSale } = this.state;
        const { updateOwnership, provenanceDetails, futureOwnershipTransfer } = this.state.data;

        const lastOwnershipChangeDate = provenanceDetails.find(d =>
            d.localID !== this.state.localID &&
            d.updateOwnership === true &&
            d.futureOwnershipTransfer === false &&
            d.dateOfSale !== null && d.dateOfSale.length > 0
        );

        const result = { ok: true, error: '' };
        let validDate = false;
        let valid = false;

        const isAdmin = (this.state.userRole === 'Administrator');
        const inBlockchain = provenanceDetails.find(d => d.uploadedToBlockchain === true);

        // Valid Future Ownership Transfer
        if (futureOwnershipTransfer) {
            validDate = dateOfSale !== null
                && moment(dateOfSale).isSameOrAfter(new Date(), 'day');

            if (!updateOwnership) {
                result.error = 'You must activate Update Ownership for a Future Ownership Transfer. ';
            }
            if (!validDate) {
                result.error += 'The Date of Sale must be greater or equal than today';
            }
            if (result.error.length) {
                result.ok = false;
                return result;
            }
        }

        if (inBlockchain && !isAdmin && !updateOwnership) {
            result.ok = false;
            result.error = 'Not allowed to add historical information. Please contact an Administrator.';
            return result;
        }

        if (!specialDate) {
            validDate =
                dateOfSale !== null &&
                moment(dateOfSale).isSameOrBefore(new Date(), "day") &&
                moment(dateOfSale).year() >= 1800;

            if (
                !validDate &&
                !futureOwnershipTransfer &&
                this.state.provenanceType !== bottleOwnerType
            ) {
                result.error = "The Date of Sale must be lower or equal than today";
            }

            const lastProvenanceDate =
                provenanceDetails.length &&
                    provenanceDetails[0] &&
                    provenanceDetails[0].updateOwnership === true
                    ? provenanceDetails[0].dateOfSale
                    : null;
            validDate =
                lastOwnershipChangeDate && updateOwnership
                    ? moment(dateOfSale).isSameOrAfter(new Date(lastOwnershipChangeDate.dateOfSale))
                    : true;
            if (updateOwnership && lastProvenanceDate !== null && !validDate) {
                result.error =
                    "The Date of Sale for an ownership transfer cannot be previous than the latest ownership transfer";
            }

            validDate =
                lastOwnershipChangeDate &&
                    !updateOwnership &&
                    !futureOwnershipTransfer
                    ? moment(dateOfSale).isSameOrBefore(new Date(lastOwnershipChangeDate.dateOfSale))
                    : true;
            if (
                !updateOwnership &&
                lastProvenanceDate !== null &&
                !validDate &&
                this.state.provenanceType !== bottleOwnerType
            ) {
                result.error =
                    "The Date of Sale for a Provenance cannot be posterior than the latest ownership transfer, unless it is an ownership transfer";
            }
        } else {
            validDate = specialDateOfSale > 0;
        }

        let errorMessage = '';
        if (updateOwnership && !futureOwnershipTransfer && ownerId === '') {
            errorMessage = 'Invalid User. Please enter a valid User ID and use search feature';
            result.error = result.error.length ? `${result.error}. ${errorMessage}` : errorMessage;
        }
        if (updateOwnership && !futureOwnershipTransfer && (proofOfPurchaseImage === null || proofOfPurchaseImage === "") && (this.state.provenanceType !== bottleOwnerType)) {
            errorMessage = 'Proof of purchase is required for an ownership transfer, please upload an image';
            result.error = result.error.length ? `${result.error}. ${errorMessage}` : errorMessage;
        }

        if (result.error.length) {
            result.ok = false;
            return result;
        }

        valid = bottleCost !== null &&
            currency &&
            provenanceType &&
            ownerInfo &&
            ownerName && (
                !updateOwnership ||
                (ownerId !== '' && proofOfPurchaseImage !== null)
            ) &&
            validDate;
        if (futureOwnershipTransfer)
            valid = futureOwnershipTransfer && provenanceType && updateOwnership;
        switch (this.state.provenanceType) {
            case 1:
            case 5:
                result.ok = valid && (vendor !== null && vendor.length > 0);
                break;
            case 2:
            case 6:
            case 7:
            case 8:
                result.ok = valid &&
                    (auctionHouse !== null && auctionHouse.length > 0) &&
                    (saleLot !== null && saleLot.length > 0) &&
                    (saleNumber !== null && saleNumber.length > 0)
                break;
            case 3:
            case 4:
                result.ok = valid && (purchasedFrom !== null && purchasedFrom.length > 0);
                break;
            case 9:
                result.ok = ownerInfo && ownerName
                    && (updateOwnership && ownerId !== '');
                break;
            case 10:
                result.ok = valid && auctionHouse;
                break;
            default:
                result.ok = false;
                break;
        }

        if (result.error) {
            result.error += '. ';
        }

        if (!result.ok) {
            result.error += 'Make sure you completed all mandatory fields.';
        }

        return result;
    };

    getUser = async (item) => {
        const isAdmin = this.state.userRole === "Administrator";

        if (item) {
            const { owner } = this.props.currentLedger;
            if (item.id && owner !== undefined && item.id === owner.userId && !isAdmin) {
                this.props.showNotification('User found is the current Ledger owner. Please select a different user.');
                this.setState({ ownerId: '' });
            } else if (item && item.id) {
                const info = `${item.country ? item.country : 'N/A'}, ${item.state ? item.state : 'N/A'}, ${item.city ? item.city : 'N/A'}`;
                const address = item.localAddress ? item.localAddress : "";
                const contactMail = item.contactEmail ? item.contactEmail : "";

                this.setState({ ownerName: item.name, ownerId: item.id, ownerInfo: info, ownerAddress: address, ownerEmail: contactMail, updateOwnership: true });
                this.props.showNotification('Warning: Adding this provenance will change this ledger’s owner.');
            }
        }
    };

    // For AutoComplete Component
    searchUsers = async () => {
        const query = this.state.query;
        try {
            if (query) {
                const result = await searchUsers(query);
                let data = [{ name: 'No results' }];

                if (result && result.length) {
                    data = result.map((u) => { return { ...u, location: `${u.city || 'N/A'}, ${u.state || 'N/A'}, ${u.country || 'N/A'}` } });
                }
                this.setState({ results: data });
            }
        } catch (error) {
            this.props.showNotification(error.content ? error.content.message : 'An error has occurred');
        }
    };

    searchDelayed = debounce(this.searchUsers, 500);

    handleSearchInput = (e) => {
        let update = {
            query: e.target.value
        };

        if (!e.target.value) {
            update.results = [];
        }

        this.setState(update);
    };

    handleSearchSelect = async (item) => {
        this.getUser(item);
        this.setState({ results: [], query: '' });
    };

    handleOwnership = () => {
        const { data } = this.state;
        const { provenanceDetails, updateOwnership } = this.state.data;
        const allowTransfer = provenanceDetails.find(details => (details.updateOwnership === true && details.uploadedToBlockchain === false)) === undefined;

        if (allowTransfer) {
            data.updateOwnership = !updateOwnership;
        } else {
            if (!updateOwnership) this.props.showNotification('This ledger already has a provenance marked for ownership transfer. Only one provenance can be set to ownership transfer at a time.');
            data.updateOwnership = false;
        }
        data.query = '';
        data.results = [];
        this.setState({ ...data });
    }

    handleFutureOwnership = () => {
        const { data } = this.state;
        const { provenanceDetails, futureOwnershipTransfer } = this.state.data;
        const allowTransfer = provenanceDetails.find(details => (details.futureOwnershipTransfer === true)) === undefined;
        if (allowTransfer) {
            data.futureOwnershipTransfer = !futureOwnershipTransfer;
            data.updateOwnership = data.futureOwnershipTransfer ? true : data.updateOwnership;
        } else {
            if (!futureOwnershipTransfer) this.props.showNotification('This ledger already has a future ownership transfer. Only one per ledger is allowed.');
            data.futureOwnershipTransfer = false;
        }

        this.setState({ ...data });
    }

    handleDialogOk = async () => {
        const { data } = this.state;
        const { provenanceDetails } = this.state.data;

        const details = provenanceDetails.filter(details => (details.futureOwnershipTransfer === false));
        data.provenanceDetails = details;
        this.setState({ ...data, deleteFuture: true });
        this.closeModal();
    };

    renderAuctionForm = () => {
        return (
            <Fragment>
                {this.renderFormHeader()}
                <ChaiRowBodyContainer style={{ backgroundColor: 'rgb(222, 224, 223)' }}>
                    <ChaiTextField
                        {...this.getLocalField('auctionHouse')}
                        label="Auction House*" style={{ width: '30%' }}
                        certificate={true}
                    />
                    <ChaiTextField
                        {...this.getLocalField('saleNumber')}
                        label="Sale Number*" style={{ width: '15%' }}
                    />
                    <ChaiTextField
                        {...this.getLocalField('saleLot')}
                        label="Sale Lot*" style={{ width: '15%' }}
                    />
                    <ChaiTextField
                        {...this.getLocalField('consignmentDescription')}
                        label="Consigment Description" style={{ width: '40%' }}
                    />
                    <ChaiImageInput
                        {...this.getLocalField('consignmentDescriptionImage', true)}
                        label={'Consigment'}
                    />
                </ChaiRowBodyContainer>
            </Fragment>
        )
    }

    renderFormHeader = () => {
        const formName = formNames.PROVENANCE;
        const typeOptions = this.state.options[formName]['provenanceTypesOptions'] || [];
        const types = this.state.data.updateOwnership
            ? (
                this.state.currentOwner !== 'N/A'
                    ? typeOptions.filter(t => t.id !== 9)
                    : typeOptions
            )
            : typeOptions.filter(t => t.id !== 9);
        return (
            this.state.creating || this.state.editing ?
                <ChaiRowBodyContainer style={{ backgroundColor: 'rgb(222, 224, 223)' }}>
                    <ChaiDropDown
                        {...this.getLocalField('provenanceType')}
                        label="Type*" style={{ width: '20%' }}
                        items={types}
                    />
                    <ChaiDropDown
                        {...this.getLocalField('currency')}
                        label="Currency*" style={{ width: '10%' }}
                        items={this.state.options[formName]['currencyTypesOptions'] || []}
                    />
                    <ChaiTextField
                        {...this.getLocalField('bottleCost')}
                        type={'number'}
                        label="Bottle Cost*" style={{ width: '10%' }}
                        numberDomain="0UR+"
                    />
                    <ChaiTextField
                        {...this.getLocalField('lotCost')}
                        type={'number'}
                        label="Lot Cost" style={{ width: '10%' }}
                        numberDomain="0UR+"
                    />
                    {
                        this.state.specialDate === false ?
                            <ChaiTextField
                                {...this.getLocalField('dateOfSale')} type={'date'}
                                label="Date Of Sale*" style={{ width: '15%' }}
                                certificate={true}
                            />
                            :
                            <ChaiDropDown
                                {...this.getLocalField('specialDateOfSale')}
                                label="Date Of Sale*" style={{ width: '15%' }}
                                items={specialDatesOptions}
                                certificate={true}
                            />
                    }
                    <ChaiSwitch
                        label='Use Special Date'
                        {...this.getBooleanField('specialDate')}
                        style={{ width: '10%', padding: '20px' }}
                    />
                    <ChaiImageInput
                        {...this.getLocalField('proofOfPurchaseImage', true)}
                        label={'Proof Of Purchase*'}
                    />
                </ChaiRowBodyContainer> : null)
    }

    renderRetailForm = () => {
        return (
            <Fragment>
                {this.renderFormHeader()}
                <ChaiRowBodyContainer style={{ backgroundColor: 'rgb(222, 224, 223)' }}>
                    <ChaiTextField
                        {...this.getLocalField('vendor')}
                        label="Vendor*" style={{ width: '45%' }}
                        certificate={true}
                    />
                </ChaiRowBodyContainer>
            </Fragment>
        )
    }

    renderPrivateSaleForm = () => {
        return (
            <Fragment>
                {this.renderFormHeader()}
                <ChaiRowBodyContainer style={{ backgroundColor: 'rgb(222, 224, 223)' }}>
                    <ChaiTextField
                        {...this.getLocalField('purchasedFrom')}
                        label="Purchase From" style={{ width: '45%' }}
                        certificate={true}
                    />
                </ChaiRowBodyContainer>
            </Fragment>
        )
    }

    renderBottleOwnerForm = () => {
        return (
            <Fragment>
                <ChaiRowBodyContainer style={{ backgroundColor: 'rgb(222, 224, 223)' }}>
                    <ChaiDropDown
                        {...this.getLocalField('provenanceType')}
                        label="Type*" style={{ width: '20%' }}
                        items={this.state.options[formNames.PROVENANCE]['provenanceTypesOptions'] || []}
                    />
                </ChaiRowBodyContainer>
            </Fragment>
        )
    }

    renderDirectSaleForm = () => {
        return (
            <Fragment>
                {this.renderFormHeader()}
                <ChaiRowBodyContainer style={{ backgroundColor: 'rgb(222, 224, 223)' }}>
                    <ChaiTextField
                        {...this.getLocalField('auctionHouse')}
                        label="Producer*" style={{ width: '30%' }}
                        certificate={true}
                    />
                    <ChaiTextField
                        {...this.getLocalField('saleNumber')}
                        label="Sale Number" style={{ width: '15%' }}
                    />
                    <ChaiTextField
                        {...this.getLocalField('saleLot')}
                        label="Sale Lot" style={{ width: '15%' }}
                    />
                    <ChaiTextField
                        {...this.getLocalField('consignmentDescription')}
                        label="Consigment Description" style={{ width: '40%' }}
                    />
                    <ChaiImageInput
                        {...this.getLocalField('consignmentDescriptionImage', true)}
                        label={'Consigment'}
                    />
                </ChaiRowBodyContainer>
            </Fragment>
        )
    }

    renderFormByType = (type) => {
        switch (type) {
            case 1:
            case 5:
                return this.renderRetailForm();
            case 2:
            case 6:
            case 7:
            case 8:
                return this.renderAuctionForm();
            case 3:
            case 4:
                return this.renderPrivateSaleForm();
            case 9:
                return this.renderBottleOwnerForm();
            case 10:
                return this.renderDirectSaleForm();
            default:
                return this.renderFormHeader();
        }
    }

    openModal = () => this.setState({ modal: true });

    closeModal = () => this.setState({ modal: false });

    renderModal = (e) => {
        const { modal } = this.state;

        return (
            <Dialog
                open={modal}
                onClose={this.closeModal}
                aria-labelledby="form-dialog-title"
                fullWidth
            >
                <DialogTitle id="confirmation-dialog-title">Delete Future Ownership Confirmation</DialogTitle>
                <DialogContent dividers>
                    <p>Are you sure you want to delete this Future Ownership Transfer?</p>
                </DialogContent>
                <DialogActions>
                    <ChaiHeaderButton label="CANCEL" onClick={() => this.closeModal()} />
                    <ChaiHeaderButton label="OK" style={{ marginLeft: '10px' }} onClick={() => this.handleDialogOk()} />
                </DialogActions>
            </Dialog>
        )
    };

    render() {
        const { results, query } = this.state;
        const footerData = {
            message: 'Please be sure to fill out the entire form before moving on.',
            buttonLabel: 'Save & Next',
            loading: this.state.loading
        };

        const searchFields = ['name', 'location', 'handle'];
        const { classes } = this.props;

        const user = JSON.parse(localStorage.getItem('user'));
        return (<Fragment>
            {this.renderModal()}
            {this.state.loading && <LinearProgress />}
            <ChaiBodyContainer>
                <ChaiRowBodyContainer>
                    <ChaiTextField
                        label="Current Owner Name*"
                        disabled={true}
                        value={this.state.currentOwner}
                        style={{ width: '20%' }}
                    />
                    <ChaiTextField
                        label="Current City, State/Province, Country"
                        disabled={true}
                        value={this.state.currentInfo}
                        style={{ width: '20%' }}
                    />
                    <ChaiTextField
                        value={this.state.currentAddress}
                        disabled={true}
                        label="Current Owner Contact Address*"
                        style={{ width: '20%' }}
                    />
                    <ChaiTextField
                        value={this.state.currentEmail}
                        disabled={true}
                        label="Current Owner Contact Email*"
                        style={{ width: '20%' }}
                    />
                    <ChaiHeaderButton
                        style={{ marginTop: 14 }}
                        label="+ Add a new provenance"
                        onClick={this.handleNewProvenance}
                        disabled={this.state.creating || this.state.editing || !this.calculateWritePermissionForms('provenanceDetails')} />
                </ChaiRowBodyContainer>
                <div style={this.state.creating || this.state.editing ? { border: '1px solid #3e3e3e' } : null}>
                    {
                        this.state.creating || this.state.editing ?
                            <ChaiRowBodyContainer style={{ backgroundColor: 'rgb(222, 224, 223)', justifyContent: 'flex-start' }}>
                                <Tooltip
                                    title={this.canEditUpdateOwnerShip() ? "Enable/Disable The ownership transfer" : "You are currently viewing/editing a certified ledger. Please contact your administrator to add historic provenance"}
                                    placement="top"
                                >
                                    <ChaiSwitch
                                        label='Ownership Transfer'
                                        {...this.getField('updateOwnership', true)}
                                        style={{ width: '25%', padding: '20px' }}
                                        disabled={this.state.loading || !this.canEditUpdateOwnerShip() || this.state.data.futureOwnershipTransfer}
                                        onChange={this.handleOwnership}
                                    />
                                </Tooltip>
                                <ChaiSwitch
                                    label='Future Ownership Transfer'
                                    {...this.getField('futureOwnershipTransfer', true)}
                                    style={{ width: '25%', padding: '20px' }}
                                    disabled={this.state.loading}
                                    onChange={this.handleFutureOwnership}
                                />
                                <ChaiAutoComplete label={"NEW OWNER SEARCH"}
                                    style={{ width: '40%' }}
                                    data={results}
                                    fields={searchFields}
                                    value={query}
                                    placeholder='Type any User name...'
                                    onSelect={this.handleSearchSelect}
                                    onChange={(e) => this.handleLocalChange(e, 'query')}
                                    adornment={
                                        <InputAdornment position="end">
                                            <SearchIcon className={classes.searchIcon} />
                                        </InputAdornment>
                                    }
                                    disabled={this.state.data.futureOwnershipTransfer}
                                />
                                {!this.state.updateOwnership &&
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                                        <ChaiSwitch
                                            label='Encrypt Owner Name*'
                                            {...this.getBooleanField('encryptOwnerName')}
                                            style={{ width: '100%', padding: '20px' }}
                                        />
                                        <ChaiSwitch
                                            label='Encrypt Owner Information*'
                                            {...this.getBooleanField('encryptOwnerInformation')}
                                            style={{ width: '100%', padding: '20px' }}
                                        />
                                    </div>}
                            </ChaiRowBodyContainer> : null
                    }
                    {
                        this.state.creating || this.state.editing ?
                            <ChaiRowBodyContainer style={{ backgroundColor: 'rgb(222, 224, 223)' }}>
                                {
                                    !this.state.data.futureOwnershipTransfer &&
                                    <Fragment>
                                        <ChaiTextField
                                            {...this.getLocalField('ownerName')}
                                            label="Owner Name*" style={{ width: '20%' }}
                                            certificate={true}
                                            disabled={this.state.data.updateOwnership}
                                        />
                                        {
                                            this.state.data.updateOwnership &&
                                            <ChaiCheck
                                                checked
                                                disabled
                                                iconColor={(this.state.ownerName && this.state.ownerName.length > 0) ?
                                                    `${classes.checkBox} ${classes.ownerSelected}` :
                                                    `${classes.checkBox} ${classes.ownerPending}`}
                                                style={{ width: '3%' }}
                                            />
                                        }
                                    </Fragment>
                                }
                                {
                                    !this.state.data.futureOwnershipTransfer ?
                                        <Fragment>
                                            <ChaiTextField
                                                {...this.getLocalField('ownerInfo')}
                                                label="City, State/Province, Country*" style={{ width: '20%' }}
                                                certificate={true}
                                            />
                                            <ChaiTextField
                                                {...this.getLocalField('ownerAddress')}
                                                label="Owner Contact Address*" style={{ width: '20%' }}
                                            />
                                            <ChaiTextField
                                                {...this.getLocalField('ownerEmail')}
                                                label="Owner Contact Email*" style={{ width: '20%' }}
                                            />
                                        </Fragment> : <div style={{ width: '80%' }}></div>
                                }
                                <ChaiHeaderButton
                                    style={{ marginTop: 14 }}
                                    disabled={this.state.loading}
                                    label="Save"
                                    onClick={this.handleProvenanceDetail} />
                                <ChaiHeaderButton
                                    style={{ marginTop: 14 }}
                                    disabled={this.state.loading}
                                    label="Cancel"
                                    onClick={this.handleCancelProvenanceDetail} />
                            </ChaiRowBodyContainer> : null
                    }
                    {this.renderFormByType(this.state.provenanceType)}
                </div>
                {
                    this.state.data.provenanceDetails && this.state.data.provenanceDetails.length ? <ChaiRowBodyContainer>
                        <ChaiTable
                            columns={columnData}
                            rowsPerPage={10}
                            data={this.state.data.provenanceDetails}
                            {...this.props} />
                    </ChaiRowBodyContainer> : null
                }
                <div style={{ paddingTop: 30 }}></div>
            </ChaiBodyContainer>
            <ChaiFooterContainer {...footerData} onClick={this.save} />
        </Fragment>
        );
    }
}

ChaiProvenance.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChaiProvenance);
