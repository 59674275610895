import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { getLedgersByUser } from './ledger';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ChaiBodyContainer from '../ChaiBodyContainer';
import ChaiLedgersTable from '../ChaiLedgersTable';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ChaiAddUsersWidget from '../ChaiShareAndSale/ChaiAddUsersWidget';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import debounce from 'debounce';
import queryString from 'query-string';

import ChaiHeaderButton from '../ChaiHeaderButton';
import ChaiNotification from '../ChaiNotification';
import { getRole } from '../../Helpers/permissions';

const bottleInformationService = require('../ChaiBottleInformation/service');

const styles = theme => ({
    tabsRoot: {
        backgroundColor: '#efefef'
    },
    tabsIndicator: {
        height: 0
    },
    tab: {
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: '1.2em',
        fontWeight: 600
    },
    tabRoot: {
        boxShadow: 'inset 7px -8px 23px -2px rgba(0,0,0,0.47)',
        backgroundColor: '#3f3e3e',
        color: 'white',
        '&$tabSelected': {
            color: '#3f3e3e',
            backgroundColor: '#efefef',
            boxShadow: 'none'
        }
    },
    list: {
        border: "2px solid #622222",
        padding: 0

    },
    disabledList: {
        backgroundColor: "#e4e4e4",
    },
    disabledListItem: { cursor: 'pointer' },
    listItem: {
        cursor: 'pointer',
        color: "#622222 ",
        "&:hover": {
            backgroundColor: '#622222',
            color: "white"
        }
    },
    button: {
        color: 'black',
        padding: '0.6em',
        fontSize: '0.9em',
        marginLeft: "auto",
        marginRight: '2em',
        float: "right",
        zIndex: "10",
        backgroundColor: "#fffefe",
        borderRadius: "5%"
    },
    tabSelected: {},
    tabContainer: { display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' },
    option: {
        color: "#a9a9a9",
        fontWeight: 500,
        cursor: "pointer"
    },
    optionSelected: {
        color: "#9a5b5a",
        fontWeight: 500,
        cursor: "pointer"
    },
    leftContainer: {
        float: "left",
        padding: "0em 2em",
        width: '70%',
        marginTop: '0.5em',
        marginLeft: '3px'

    }
});

const columnData = [
    { id: 'ledgerNumber', label: 'Ledger Number', sorteable: true },
    { id: 'authenticator', label: 'Authenticator' },
    { id: 'wineName', label: 'Bottle Name' },
    { id: 'lastEdited', label: 'Last Edited', sorteable: true },
    { id: 'showCertificate', label: 'Certificate', link: true }
];

const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
const isAdmin = user && user.rol === 'Administrator';

class ChaiLedger extends Component {

    constructor(props) {
        super(props);
        this.equivalents = { ledgerNumber: "Id", lastEdited: "CreatedOn" };

        // Delete template
        localStorage.removeItem('template');
        // Delete soft save
        localStorage.removeItem('bottleInfo');
        localStorage.removeItem('authInfo');

        let previousSearch = localStorage.getItem("ledgerSearch");

        if (previousSearch) {
            previousSearch = JSON.parse(localStorage.getItem("ledgerSearch"));
        } else {
            previousSearch = {
                ownLedger: true,
                onlyConsumed: false,
                hasCertificate: null,
                textFilter: '',
                order: 'asc',
                orderBy: 'ledgerNumber',
                page: 0,
                rowsPerPage: 500,
                canViewOthersLedgers: false,
                canViewConsumedLedgers: false
            };

            localStorage.setItem("ledgerSearch", JSON.stringify(previousSearch));
        }

        const tabId = previousSearch.ownLedger
            ? (previousSearch.onlyConsumed ? 2 : 0)
            : (previousSearch.onlyConsumed ? 0 : 1);
        const tabsAvailable = 1 + previousSearch.canViewOthersLedgers +
            previousSearch.canViewConsumedLedgers;
        const tabIndex = Math.min(tabId, tabsAvailable);

        this.state = {
            tab: tabId,
            tabIndex: tabIndex,
            ledgers: [],
            othersLedgers: [],
            filter: false,
            modal: false,
            sharedLedgers: [],
            hasCertificate: previousSearch.hasCertificate,
            textFilter: previousSearch.textFilter,
            order: previousSearch.order,
            orderBy: previousSearch.orderBy,
            page: previousSearch.page ? previousSearch.page : 0,
            rowsPerPage: previousSearch.rowsPerPage ? previousSearch.rowsPerPage : 500,
            totalCount: 0,
            loading: false,
            selected: [],
            data: [],
            ownLedger: previousSearch.ownLedger,
            onlyConsumed: previousSearch.onlyConsumed,
            consumeBottlesModal: false,
            unconsumeBottlesModal: false,
            notificationMessage: '',
            openNotificationMessage: false,
            consumingBottles: false,
            unconsumingBottles: false,
            canViewOthersLedgers: false,
            canViewConsumedLedgers: false,
            userRole: null
        }
    }

    componentDidMount() {
        this.callLoadLedgersWithParams();

        const userId = JSON.parse(localStorage.getItem('user')).id;
        getRole(userId)
            .then(userRole => {
                this.setState({ userRole })
            });
    }

    updateStateForSearch = (stateForSearch) => {
        let previousSearch = localStorage.getItem("ledgerSearch") ?
            JSON.parse(localStorage.getItem("ledgerSearch")) : null;

        previousSearch = { ...previousSearch, ...stateForSearch };
        localStorage.setItem("ledgerSearch", JSON.stringify(previousSearch));
    };

    handleChangeTextFilter = (event) => {
        let textFilter = event.target.value;
        this.setState({ textFilter });

        this.updateStateForSearch({ textFilter });
        setTimeout(() => {
            this.callLoadLedgersWithParams(true);
        }, 0);
    };

    handleChangeRowsPerPage = event => {
        let rowsPerPage = event.target.value;
        this.setState({ rowsPerPage });

        this.updateStateForSearch({ rowsPerPage });
        setTimeout(() => {
            this.callLoadLedgersWithParams();
        }, 0);
    };

    handleRequestSort = (event, property) => {
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        console.info('SEARCH sort', order, property);

        this.setState({ order, orderBy: property });

        this.updateStateForSearch({ order, orderBy: property });
        setTimeout(() => {
            this.callLoadLedgersWithParams();
        }, 0);
    };

    handleChangePage = (event, page) => {
        this.setState({ page });

        this.updateStateForSearch({ page });
        setTimeout(() => {
            this.callLoadLedgersWithParams();
        }, 0);
    };

    handleTab = (event, tabIndex) => {
        const tabId = parseInt(event.target.closest('button').dataset.id);
        const ownLedger = tabId === 0 || tabId === 2;
        const onlyConsumed = tabId === 2;

        this.setState({
            ownLedger: ownLedger,
            onlyConsumed: onlyConsumed,
            tabIndex: tabIndex,
            tab: tabId
        });

        this.updateStateForSearch({
            ownLedger: ownLedger,
            onlyConsumed: onlyConsumed
        });

        setTimeout(() => {
            this.callLoadLedgersWithParams();
        }, 0);
    };

    handleCheck = (event, ledger) => {
        console.log(event, ledger)
        const data = this.state.data.map(oneLedger => {
            if (oneLedger.ledgerNumber === ledger.ledgerNumber) {
                oneLedger.checked = event.target.checked;
            }
            return oneLedger;
        });
        this.setState({ data });
        this.handleCheckLedger(event, [ledger]);
    };

    checkAllOnPage = async (ev) => {
        const data = this.state.data.map((oneLedger) => {
            return { ...oneLedger, checked: ev.target.checked };
        });
        this.handleCheckLedger(ev, data);
        this.setState({ data });
        console.log("ledgers in table", data);
    };

    openModal = (key) => {
        if (this.state.sharedLedgers.length > 0 &&
            ((this.state.tab !== 2 && this.canRoleShare()) || this.state.tab === 0))
            this.setState({ modal: true, actualKey: key });
    };

    closeModal = () => {
        this.setState({ modal: false });
    };

    openConsumeBottlesModal = () => {
        if (this.state.sharedLedgers.length) {
            this.setState({ consumeBottlesModal: true });
        }
    };

    openUnconsumeBottlesModal = () => {
        if (this.state.sharedLedgers.length) {
            this.setState({ unconsumeBottlesModal: true });
        }
    };

    closeConsumeBottlesModal = () => {
        this.setState({ consumeBottlesModal: false });
    };

    closeUnconsumeBottlesModal = () => {
        this.setState({ unconsumeBottlesModal: false });
    };

    canChangesConsumptionBottleStatus = (ledger, consumed) => {
        const user = JSON.parse(localStorage.getItem('user'));
        return ledger.consumed != consumed && (
            this.isAdmin() ||
            ledger.creator.userId == user.id ||
            ledger.owner.userId == user.id ||
            ledger.allowedToEdit.userId == user.id
        );
    };

    consumeBottles = async () => {
        const bottles = this.state.sharedLedgers
            .filter(x => this.canChangesConsumptionBottleStatus(x, true))
            .map(x => ({
                ledgerNumber: x.ledgerNumber,
                consumed: true
            }));
        this.setState({
            consumingBottles: true,
            notificationMessage: 'Consuming bottles...',
            openNotificationMessage: true
        });
        await bottleInformationService.saveBottleInfoList({ data: bottles });
        setTimeout(() => {
            this.setState({ openNotificationMessage: false });
            this.state.openNotificationMessagge = false;
            setTimeout(() => {
                this.setState({
                    notificationMessage: 'Bottles successfully marked as consumed.',
                    openNotificationMessage: true
                });
                setTimeout(() => {
                    this.setState({ consumingBottles: false, openNotificationMessage: false });
                }, 2000);
            }, 200);
            this.closeConsumeBottlesModal();
            this.setState({ sharedLedgers: [] });
            this.callLoadLedgersWithParams();
        }, 200);
    };

    unconsumeBottles = async () => {
        const bottles = this.state.sharedLedgers
            .filter(x => this.canChangesConsumptionBottleStatus(x, false))
            .map(x => ({
                ledgerNumber: x.ledgerNumber,
                consumed: false
            }));
        this.setState({
            unconsumingBottles: true,
            notificationMessage: 'Unconsuming bottles...',
            openNotificationMessage: true
        });
        await bottleInformationService.saveBottleInfoList({ data: bottles });
        setTimeout(() => {
            this.setState({ openNotificationMessage: false });
            this.state.openNotificationMessagge = false;
            setTimeout(() => {
                this.setState({
                    notificationMessage: 'Bottles successfully marked as unconsumed.',
                    openNotificationMessage: true
                });
                setTimeout(() => {
                    this.setState({ unconsumingBottles: false, openNotificationMessage: false });
                }, 2000);
            }, 200);
            this.closeUnconsumeBottlesModal();
            this.setState({ sharedLedgers: [] });

            let allBottlesUnconsumed = this.state.data.length === bottles.length;
            if (this.isCollector() && allBottlesUnconsumed) {
                this.loadMyLedgersTab();
            } else {
                this.callLoadLedgersWithParams();
            }

        }, 200);
    };

    loadMyLedgersTab = () => {
        this.setState({
            ownLedger: true,
            onlyConsumed: false,
            tabIndex: 0,
            tab: 0
        });

        this.updateStateForSearch({
            ownLedger: true,
            onlyConsumed: false
        });

        setTimeout(() => {
            this.callLoadLedgersWithParams();
        }, 0);
    }

    onAddUsers = () => {
        this.closeModal();
        this.setState({ sharedLedgers: [] });
        this.props.handleEnableShare(false);
    };

    renderModal = () => {
        const { modal } = this.state;

        return (
            <Dialog
                open={modal}
                onClose={this.closeModal}
                aria-labelledby="form-dialog-title"
                maxWidth={"md"}
                fullWidth
            >
                <DialogContent>
                    <ChaiAddUsersWidget {...this.props}
                        ledgers={this.state.sharedLedgers}
                        onAddUsers={this.onAddUsers}
                        previousUsers={[]}
                        newUsers={() => { }} />
                </DialogContent>
                <DialogActions style={{ marginRight: 25 }}>
                </DialogActions>
            </Dialog>
        )
    };

    renderConsumeBottlesModal = () => {
        const {
            consumingBottles,
            consumeBottlesModal,
            notificationMessage,
            openNotificationMessage,
            sharedLedgers
        } = this.state;

        const winesThatCanBeConsumed = sharedLedgers
            .filter(x => this.canChangesConsumptionBottleStatus(x, true));
        const winesThatCantBeConsumed = sharedLedgers
            .filter(x => !this.canChangesConsumptionBottleStatus(x, true));
        const hasWinesToConsume = winesThatCanBeConsumed.length;
        const hasWinesThatCantConsume = winesThatCantBeConsumed.length;

        return (
            <div>
                <Dialog
                    open={consumeBottlesModal}
                    onClose={this.closeConsumeBottlesModal}
                    arial-labelledby="from-dialog-title"
                    scroll={"paper"}
                    maxWidth={"md"}
                >
                    <DialogTitle>
                        Consume bottles confirmation
                    </DialogTitle>
                    <DialogContent dividers>
                        <div style={{ display: hasWinesThatCantConsume ? 'block' : 'none' }}>
                            <h4>These wines cannot be consumed by you:</h4>
                            <ul>{winesThatCantBeConsumed.map(x => <li>{x.qrCode ? x.qrCode + ' - ': ''} {x.wineName}</li>)}</ul>
                        </div>
                        <div style={{ display: hasWinesToConsume ? 'block' : 'none' }}>
                            <h4>You are about to consume the following bottles:</h4>
                            <ul>{winesThatCanBeConsumed.map(x => <li>{x.qrCode ? x.qrCode + ' - ': ''}{x.wineName}</li>)}</ul>
                        </div>
                        <div style={{ display: !hasWinesToConsume ? 'block' : 'none' }}>
                            <strong>You cannot consume any of the wines you selected.</strong>
                        </div>
                    </DialogContent>
                    <DialogActions style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                        <span style={{
                            paddingRight: '16px',
                            display: hasWinesToConsume && !hasWinesThatCantConsume ?
                                'block' :
                                'none'
                        }}>
                            Are you sure?
                        </span>
                        <span style={{
                            paddingRight: '16px',
                            display: hasWinesToConsume && hasWinesThatCantConsume ?
                                'block' :
                                'none'
                        }}>
                            Are you sure you want to consume the wines anyway?
                        </span>
                        <ChaiHeaderButton
                            onClick={this.closeConsumeBottlesModal}
                            value={hasWinesToConsume ? 'Cancel' : 'Close'}
                            label={hasWinesToConsume ? 'Cancel' : 'Close'}
                            disabled={consumingBottles}
                        ></ChaiHeaderButton>
                        <ChaiHeaderButton
                            onClick={this.consumeBottles}
                            style={{
                                marginLeft: '10px',
                                display: hasWinesToConsume ? 'inline-block' : 'none'
                            }}
                            value="Confirm"
                            label="Confirm">
                            disabled={consumingBottles}
                            ></ChaiHeaderButton>
                    </DialogActions>
                </Dialog>
                <ChaiNotification message={notificationMessage} open={openNotificationMessage} />
            </div>
        );
    };

    renderUnconsumeBottlesModal = () => {
        const {
            unconsumingBottles,
            unconsumeBottlesModal,
            notificationMessage,
            openNotificationMessage,
            sharedLedgers
        } = this.state;

        const winesThatCanBeUnconsumed = sharedLedgers
            .filter(x => this.canChangesConsumptionBottleStatus(x, false));
        const winesThatCantBeUnconsumed = sharedLedgers
            .filter(x => !this.canChangesConsumptionBottleStatus(x, false));
        const hasWinesToUnconsume = winesThatCanBeUnconsumed.length;
        const hasWinesThatCantUnconsume = winesThatCantBeUnconsumed.length;

        return (
            <div>
                <Dialog
                    open={unconsumeBottlesModal}
                    onClose={this.closeUnconsumeBottlesModal}
                    arial-labelledby="from-dialog-title"
                    scroll={"paper"}
                    maxWidth={"md"}
                >
                    <DialogTitle>
                        Unconsume bottles confirmation
                    </DialogTitle>
                    <DialogContent dividers>
                        <div style={{ display: hasWinesThatCantUnconsume ? 'block' : 'none' }}>
                            <h4>These wines cannot be unconsumed:</h4>
                            <ul>{winesThatCantBeUnconsumed.map(x => <li>{x.qrCode ? x.qrCode + ' - ': ''} {x.wineName}</li>)}</ul>
                        </div>
                        <div style={{ display: hasWinesToUnconsume ? 'block' : 'none' }}>
                            <h4>You are about to unconsume the following bottles:</h4>
                            <ul>{winesThatCanBeUnconsumed.map(x => <li>{x.qrCode ? x.qrCode + ' - ': ''} {x.wineName}</li>)}</ul>
                        </div>
                        <div style={{ display: !hasWinesToUnconsume ? 'block' : 'none' }}>
                            <strong>You cannot unconsume any of the wines you selected.</strong>
                        </div>
                    </DialogContent>
                    <DialogActions style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                        <span style={{
                            paddingRight: '16px',
                            display: hasWinesToUnconsume && !hasWinesThatCantUnconsume ?
                                'block' :
                                'none'
                        }}>
                            Are you sure?
                        </span>
                        <span style={{
                            paddingRight: '16px',
                            display: hasWinesToUnconsume && hasWinesThatCantUnconsume ?
                                'block' :
                                'none'
                        }}>
                            Are you sure you want to consume the wines anyway?
                        </span>
                        <ChaiHeaderButton
                            onClick={this.closeUnconsumeBottlesModal}
                            value={hasWinesToUnconsume ? 'Cancel' : 'Close'}
                            label={hasWinesToUnconsume ? 'Cancel' : 'Close'}
                            disabled={unconsumingBottles}>
                        </ChaiHeaderButton>
                        <ChaiHeaderButton
                            onClick={this.unconsumeBottles}
                            style={{
                                marginLeft: '10px',
                                display: hasWinesToUnconsume ? 'inline-block' : 'none'
                            }}
                            value="Confirm"
                            label="Confirm">
                            disabled={unconsumingBottles}
                        </ChaiHeaderButton>
                    </DialogActions>
                </Dialog>
                <ChaiNotification message={notificationMessage} open={openNotificationMessage} />
            </div>
        );
    };

    handleCheckLedger = (event, ledgers) => {
        if (event.target.checked) {
            let sharedLedgers = this.state.sharedLedgers;
            sharedLedgers = sharedLedgers.concat(ledgers);
            this.setState({ sharedLedgers });
        } else {
            const ledgersId = ledgers.map(led => led.ledgerNumber)
            const sharedLedgers = this.state.sharedLedgers.filter((led) => {
                return ledgersId.indexOf(led.ledgerNumber) === -1;
            });
            this.setState({ sharedLedgers });
        }
    };

    handleLoading = (loading) => {
        this.setState({ loading });
    };

    handleClick = event => {
        this.setState({ filter: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ filter: false });
    };

    handleFilterSetup = (hasCertificate) => {
        this.setState({ hasCertificate });

        this.updateStateForSearch({ hasCertificate });
        setTimeout(() => {
            this.callLoadLedgersWithParams();
        }, 0);
    };

    renderCertificateFilters = () => {
        const { classes } = this.props;
        const { loading } = this.state;

        const isAll = this.state.hasCertificate === null;
        const isWithCertificate = this.state.hasCertificate === true;

        return (<Fragment>
            <a className={isAll ? classes.optionSelected : classes.option}
                disabled={loading}
                onClick={() => this.handleFilterSetup(null)}
            >All</a> |
            <a className={isWithCertificate && !isAll ? classes.optionSelected : classes.option}
                disabled={loading}
                onClick={() => this.handleFilterSetup(true)}
            > Ledgers With Certificate</a> |
            <a className={!isWithCertificate && !isAll ? classes.optionSelected : classes.option}
                disabled={loading}
                onClick={() => this.handleFilterSetup(false)}
            > Ledgers Without a Certificate</a>
        </Fragment>);
    };

    renderBulkActions = () => {
        const { classes } = this.props;
        return (
            <div className={classes.buttonContainer}>
                <button
                    className={classes.button}
                    onClick={this.handleClick}
                    variant="flat"
                    color="primary"
                >
                    Bulk Actions <span style={{ fontSize: "0.6em" }}>▼</span>
                </button>
            </div>
        )
    };

    async loadLedgers(filterParams) {
        this.handleLoading(true);
        const sort = this.equivalents[filterParams.orderBy];

        try {
            filterParams.page = filterParams.page + 1;
            filterParams.sort = sort;

            delete filterParams.orderBy;
            const myQueryString = queryString.stringify(filterParams);

            let result = await getLedgersByUser(myQueryString);
            let ledgers = result.data;
            let totalCount = result.count;

            // If it doesn't retrieve data, I erase the last search as it will cause the user a white
            // page next time he access Ledgers List
            /* if (!totalCount) {
                localStorage.removeItem("ledgerSearch");
            } */

            ledgers = ledgers.map((ledger) => {
                ledger.lastEdited = new Date(ledger.lastEdited).toLocaleDateString();
                const nameParts = ledger.wineName.split(' ');
                const isNv = nameParts[0] === '1';
                const isMv = nameParts[0] === '2';
                let specialVintage = isNv ? "NV" : "MV";
                if (isNv || isMv) {
                    nameParts.shift();
                    ledger.wineName = `${specialVintage} ${nameParts.join(' ')}`;
                }
                ledger.checked = this.state.sharedLedgers.find(oneLedger =>
                    oneLedger.ledgerNumber === ledger.ledgerNumber) !== undefined;
                ledger.showCertificate =
                    ledger.showCertificate ? `ledger/${ledger.ledgerNumber}` : null;
                ledger.allowedToView = ledger.allowedToView || [];
                ledger.allowedToEdit = ledger.allowedToEdit || null;
                ledger.allowedToSell = ledger.allowedToSell || null;
                return ledger;
            });

            this.state.canViewOthersLedgers = result.canViewOthersLedgers;
            this.state.canViewConsumedLedgers = result.canViewConsumedLedgers;
            this.state.data = ledgers;
            this.state.totalCount = totalCount;

            if ((this.state.tab === 1 && !result.canViewOthersLedgers) ||
                (this.state.tab === 2 && !result.canViewConsumedLedgers)) {
                this.state.tabIndex = this.state.tab = 0;
            }

            this.setState(this.state);
            this.updateStateForSearch({
                canViewOthersLedgers: result.canViewOthersLedgers,
                canViewConsumedLedgers: result.canViewConsumedLedgers
            });

            this.handleLoading(false);
        } catch (err) {
            this.handleLoading(false);
            console.log(err);
            this.props.showNotification(err.message, 'warning', { autoHideDuration: 10000 });
        }
    }

    // Duplicated on purpose as in the future some roles may be added to the sharing feature
    canRoleShare = () => {
        const { userRole } = this.state;
        return userRole === 'Administrator';
    };

    isAdmin = () => {
        const { userRole } = this.state;
        return userRole === 'Administrator';
    };

    isCollector = () => {
        const { userRole } = this.state;
        return userRole === 'Collector';
    }

    getLedgersByUserHandler = async (config) => {
        const result = await getLedgersByUser(config);
        if ((this.state.tab === 1 && !result.canViewOthersLedgers) ||
            (this.state.tab === 2 && !result.canViewConsumedLedgers)) {
            this.state.tabIndex = this.state.tab = 0;
        }
        this.setState(this.state);
        return result;
    };

    loadLedgersDelayed = debounce(this.loadLedgers, 500);

    // Default to null values so it takes the current state values
    callLoadLedgersWithParams = (delayed) => {
        const {
            page,
            rowsPerPage,
            ownLedger,
            onlyConsumed,
            textFilter,
            order,
            orderBy,
            hasCertificate
        } = this.state;

        const filterParams = {
            page,
            rowsPerPage,
            ownLedger,
            onlyConsumed,
            textFilter,
            order,
            orderBy,
            hasCertificate
        };

        if (delayed) {
            this.loadLedgersDelayed(filterParams);
        } else {
            this.loadLedgers(filterParams);
        }
    };

    render() {
        const {
            tab,
            tabIndex,
            sharedLedgers,
            textFilter,
            loading,
            page,
            order,
            orderBy,
            rowsPerPage,
            totalCount,
            data,
            selected,
            filter,
            canViewOthersLedgers,
            canViewConsumedLedgers,
            userRole
        } = this.state;

        const {
            classes,
            handleSelectLedger,
            theme,
            ...otherProps
        } = this.props;

        const canUserRoleShare = this.canRoleShare();
        const canShare = sharedLedgers.length > 0 && ((tab !== 2 && canUserRoleShare) || tab === 0);
        const consumeBottlesDisabled = !this.state.sharedLedgers.filter(x => !x.consumed).length;
        const unconsumeBottlesDisabled = !this.state.sharedLedgers.filter(x => x.consumed).length;

        return (
            <Fragment>
                {loading && <LinearProgress />}
                <ChaiBodyContainer style={{ padding: 0, boxShadow: '1px 1px 5px 0px rgba(0,0,0,0.49)' }}>
                    {
                        <Fragment>
                            <AppBar position='static' style={{ boxShadow: 'none' }}>
                                <Tabs value={tabIndex} onChange={this.handleTab} fullWidth
                                    classes={{
                                        root: classes.tabsRoot,
                                        indicator: classes.tabsIndicator
                                    }}>
                                    <Tab classes={{
                                        root: classes.tabRoot,
                                        label: classes.tab,
                                        selected: classes.tabSelected
                                    }} label='My Ledgers' data-id="0" />
                                    {
                                        canViewOthersLedgers ?
                                            <Tab classes={{
                                                root: classes.tabRoot,
                                                label: classes.tab,
                                                selected: classes.tabSelected
                                            }} label='Others Ledgers' data-id="1" /> :
                                            ''
                                    }
                                    {
                                        canViewConsumedLedgers ?
                                            <Tab classes={{
                                                root: classes.tabRoot,
                                                label: classes.tab,
                                                selected: classes.tabSelected
                                            }} label='Consumed Ledgers' data-id="2" /> :
                                            ''
                                    }
                                </Tabs>
                            </AppBar>
                            <div style={{ width: '100%', marginTop: '2.5em' }}>
                                <Fragment> {this.renderBulkActions()} </Fragment>
                                <Popover
                                    open={Boolean(filter)}
                                    onClose={this.handleClose}
                                    anchorEl={filter}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}>
                                    <List className={
                                        !this.state.sharedLedgers.length ?
                                            classes.disabledList :
                                            classes.list
                                    }>
                                    <ListItem
                                        onClick={this.openModal}
                                        className={!canShare ?
                                            classes.disabledListItem : classes.listItem}
                                        disabled={!canShare}>
                                        <ListItemText
                                            primary='Share Ledgers'
                                            disableTypography={true}
                                        />
                                    </ListItem>
                                    {
                                        tab !== 2 ?
                                            <ListItem
                                                onClick={this.openConsumeBottlesModal}
                                                className={
                                                    consumeBottlesDisabled ?
                                                    classes.disabledListItem :
                                                    classes.listItem
                                                }
                                                disabled={consumeBottlesDisabled}
                                            >
                                                <ListItemText
                                                    primary="Consume Bottles"
                                                    disableTypography={true}
                                                />
                                            </ListItem> : ''
                                    }
                                    {
                                        tab === 2 ?
                                            <ListItem
                                                onClick={this.openUnconsumeBottlesModal}
                                                className={
                                                    unconsumeBottlesDisabled ?
                                                    classes.disabledListItem :
                                                    classes.listItem
                                                }
                                                disabled={unconsumeBottlesDisabled}
                                            >
                                                <ListItemText
                                                    primary="Unconsume Bottles"
                                                    disableTypography="true"
                                                />
                                            </ListItem> : ''
                                    }
                                    </List>
                                </Popover>
                                <div className={classes.leftContainer}>
                                    {this.renderCertificateFilters()}
                                </div>
                            </div>
                            <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={tab}
                            >
                                <div
                                    dir={theme.direction}
                                    className={classes.tabContainer}
                                    style={{ padding: '1em 2em 2em 2em' }}
                                >
                                    {tab === 0 &&
                                        <ChaiLedgersTable
                                            columns={columnData}
                                            ownLedger={true}
                                            hasCertificate={this.state.hasCertificate}
                                            onClick={handleSelectLedger}
                                            {...otherProps}
                                            enableCheckBox={true}
                                            onCheck={this.handleCheckLedger}
                                            enableTextBoxFilter={true}
                                            handleChangeTextFilter={this.handleChangeTextFilter}
                                            textFilter={textFilter}
                                            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            handleRequestSort={this.handleRequestSort}
                                            handleChangePage={this.handleChangePage}
                                            page={page}
                                            rowsPerPage={rowsPerPage}
                                            order={order}
                                            orderBy={orderBy}
                                            totalCount={totalCount}
                                            handleCheck={this.handleCheck}
                                            checkAllOnPage={this.checkAllOnPage}
                                            loading={loading}
                                            data={data}
                                            selected={selected}
                                        />
                                    }
                                </div>
                                <div
                                    dir={theme.direction}
                                    className={classes.tabContainer}
                                    style={{ padding: '1em 2em 2em 2em' }}
                                >
                                    {tab === 1 &&
                                        <ChaiLedgersTable
                                            columns={columnData}
                                            ownLedger={false}
                                            hasCertificate={this.state.hasCertificate}
                                            onClick={handleSelectLedger}
                                            {...otherProps}
                                            enableCheckBox={true}
                                            enableTextBoxFilter={true}
                                            onCheck={this.handleCheckLedger}
                                            handleChangeTextFilter={this.handleChangeTextFilter}
                                            textFilter={textFilter}
                                            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            handleRequestSort={this.handleRequestSort}
                                            handleChangePage={this.handleChangePage}
                                            page={page}
                                            rowsPerPage={rowsPerPage}
                                            order={order}
                                            orderBy={orderBy}
                                            totalCount={totalCount}
                                            handleCheck={this.handleCheck}
                                            checkAllOnPage={this.checkAllOnPage}
                                            loading={loading}
                                            data={data}
                                            selected={selected}
                                        />
                                    }
                                </div>
                                <div
                                    key='2'
                                    dir={theme.direction}
                                    className={classes.tabContainer}
                                    style={{ padding: '1em 2em 2em 2em' }}
                                >
                                    {tab === 2 &&
                                        <ChaiLedgersTable
                                            columns={columnData}
                                            ownLedger={true}
                                            hasCertificate={this.state.hasCertificate}
                                            onClick={handleSelectLedger}
                                            {...otherProps}
                                            enableCheckBox={true}
                                            enableTextBoxFilter={true}
                                            onCheck={this.handleCheckLedger}
                                            handleChangeTextFilter={this.handleChangeTextFilter}
                                            textFilter={textFilter}
                                            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            handleRequestSort={this.handleRequestSort}
                                            handleChangePage={this.handleChangePage}
                                            page={page}
                                            rowsPerPage={rowsPerPage}
                                            order={order}
                                            orderBy={orderBy}
                                            totalCount={totalCount}
                                            handleCheck={this.handleCheck}
                                            checkAllOnPage={this.checkAllOnPage}
                                            loading={loading}
                                            data={data}
                                            selected={selected}
                                            onlyConsumed={true}
                                        />
                                    }
                                </div>
                            </SwipeableViews>
                        </Fragment>
                    }
                    {this.renderModal()}
                    {this.renderConsumeBottlesModal()}
                    {this.renderUnconsumeBottlesModal()}
                </ChaiBodyContainer>
            </Fragment>
        );
    };
}

ChaiLedger.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ChaiLedger);
