import React from "react";
import {
    ChaiAntifraudMenuIcon,
    ChaiBottleDataMenuIcon,
    ChaiBottleInfoMenuIcon,
    ChaiChainOfCustodytMenuIcon,
    ChaiClosuresMenuIcon,
    ChaiCounterfeitMenuIcon,
    ChaiGlassMenuIcon,
    ChaiLabelsMenuIcon,
    ChaiLedgerMenuIcon,
    ChaiMeasurementsMenuIcon
} from "../ChaiMenuIcons";

import formNames from '../ChaiBaseForm/formNames';
import { getPermissions } from '../ChaiBaseForm/service';


export const defaultMenuItemsData = [
    {
        menuTitle: 'Wine Ledger',
        icon: <ChaiLedgerMenuIcon />,
        path: '/',
        formName: formNames.LEDGERS,
        completed: false,
        hideInStepper: true
    },
    {
        menuTitle: 'Bottle Info',
        title: 'Bottle Information',
        icon: <ChaiBottleInfoMenuIcon />,
        path: '/bottleInformation',
        formName: formNames.BOTTLEINFORMATION,
        completed: false,
        mandatory: true,
        valid: null,
    },
    {
        menuTitle: 'Bottle Data',
        title: 'Bottle Data & Unique Conditions',
        icon: <ChaiBottleDataMenuIcon />,
        path: '/bottleInformation',
        formName: formNames.BOTTLEDATA,
        completed: false,
        mandatory: true,
        valid: null,
    },
    {
        menuTitle: 'Measurements',
        icon: <ChaiMeasurementsMenuIcon />,
        path: '/bottleInformation',
        formName: formNames.MEASUREMENTS,
        completed: false,
        mandatory: true,
        valid: null,
    },
    {
        menuTitle: 'Main Label',
        icon: <ChaiLabelsMenuIcon />,
        path: '/bottleInformation',
        formName: formNames.MAINLABEL,
        completed: false,
        mandatory: true,
        valid: null,
    },
    {
        menuTitle: 'Other Labels',
        icon: <ChaiLabelsMenuIcon />,
        path: '/bottleInformation',
        formName: formNames.OTHERLABEL,
        completed: false,
        mandatory: false,
        valid: null,
    },
    {
        menuTitle: 'Closure',
        icon: <ChaiClosuresMenuIcon />,
        path: '/bottleInformation',
        formName: formNames.CLOSURECORK,
        completed: false,
        mandatory: true,
        valid: null,
    },
    {
        menuTitle: 'Glass',
        icon: <ChaiGlassMenuIcon />,
        path: '/bottleInformation',
        formName: formNames.GLASS,
        completed: false,
        mandatory: true,
        valid: null,
    },
    {
        menuTitle: 'Antifraud',
        icon: <ChaiAntifraudMenuIcon />,
        path: '/bottleInformation',
        formName: formNames.ANTIFRAUD,
        completed: false,
        valid: null,
    },
    {
        menuTitle: 'Counterfeit',
        icon: <ChaiCounterfeitMenuIcon />,
        path: '/bottleInformation',
        formName: formNames.COUNTERFEIT,
        completed: false,
        mandatory: false,
        valid: null,
    },
    {
        menuTitle: 'Provenance',
        icon: <ChaiChainOfCustodytMenuIcon />,
        path: '/provenance',
        formName: formNames.PROVENANCE,
        completed: false,
        mandatory: true,
        valid: null,
    },
    {
        menuTitle: 'Administrative',
        icon: <ChaiChainOfCustodytMenuIcon />,
        path: '/authenticator',
        formName: formNames.ADMINISTRATIVE,
        completed: false,
        mandatory: true,
        valid: null,
    },
    {
        menuTitle: 'Authenticator',
        icon: <ChaiChainOfCustodytMenuIcon />,
        path: '/authenticator',
        formName: formNames.AUTHENTICATOR,
        completed: false,
        mandatory: true,
        valid: null,
    },
    {
        menuTitle: 'Share & Sale',
        icon: <ChaiChainOfCustodytMenuIcon />,
        path: '/shareAndSale',
        formName: formNames.SHARE,
        completed: false,
        mandatory: false,
        valid: null,
    }
];


/**
 * This method filters the allowed menus by role permission.
 * */
export const buildMenuByPermission = () => {
    let permission = getPermissions();
    if (permission) {
        let menuPermission = permission.static || [];
        return defaultMenuItemsData
            .filter(menuItem => menuPermission
                .find(menuP => menuP.split(':')[0] === menuItem.formName && menuP.split(':')[1].indexOf('rw') > -1)
            );
    } else {
        return defaultMenuItemsData;
    }
};


/**
 * Decorates the buildMenuByPermission method for special rules.
 * */
export const buildMenu = (currentLedger) => {
    let finalMenu = buildMenuByPermission();
    const user = JSON.parse(localStorage.getItem('user'));
    if (currentLedger) {
        const isOwner = (
            (currentLedger.owner.userId !== "" && currentLedger.owner.userId === user.id)
            || (currentLedger.owner.userId === "" && currentLedger.creator.userId === user.id)
        );
        const isAdmin = user.rol === "Administrator";
        const isTCM = user.rol === "TCM Authenticator";
        const isCollector = user.rol === "Collector";

        if (!(isOwner || isAdmin)) {
            // Remove SHARE ITEM
            finalMenu = finalMenu.filter(item => item.formName !== formNames.SHARE);
        }
        if (currentLedger.isAuthentic || (!currentLedger.isAuthentic && !isAdmin && !isTCM) || 

            (currentLedger && currentLedger.isAuthentic === undefined)) {
            finalMenu = finalMenu.filter(item => item.formName !== formNames.COUNTERFEIT)
        }

        if (isCollector) {
            // Change  menuTitle when user is Collector
            finalMenu.forEach(item => {
                if (item.formName === formNames.AUTHENTICATOR) {
                    item.menuTitle = "Wine Location";
                    item.mandatory = false;
                }
            })
        }
        return finalMenu;
    } else {
        return finalMenu;
    }
};
