import React, { Component } from 'react';
import { HashRouter as Router, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import _ from 'lodash';
import fetchIntercept from 'fetch-intercept';

import ChaiBlockchainUser from '../ChaiBlockchainUser';
import ChaiContainer from '../ChaiContainer';
import ChaiHeader from '../ChaiHeader';
import ChaiHeaderButton from '../ChaiHeaderButton';
import ChaiModal from '../ChaiModal';
import ChaiNotification from '../ChaiNotification';
import ChaiUploadSection from '../ChaiUploadSection';

import { buildMenu } from './menuItemData';
import { logOut } from "../ChaiAuthorization/service";

const routeExceptions = [
    /\/ledger\/.*/,
    /\/pl\/.*/,
    /\/recoverpassword\/.*/
];

const unregister = fetchIntercept.register({
    response: function (response) {
        if (response.status === 401) {
            localStorage.setItem('expired', JSON.stringify(true));

            unregister();
            logOut();

            localStorage.removeItem('expired');
        }
        return response;
    }
});

const styles = theme => ({
    container: { padding: '20px', left: '50%', right: '50%' },
    dialogRoot: { width: '100%', maxWidth: 'none', height: '80%' },
    dialogRootUpload: { width: '50%', maxWidth: 'none', height: 'auto' }
});

const chaiTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#b5615e',
        },
    }
});

class ChaiMain extends Component {
    constructor(props) {
        super(props);

        let menuItemsData = buildMenu();
        let menuItemsDataOriginal = [...menuItemsData];

        this.state = {
            collapsed: true,
            menuItemsData,
            menuItemsDataOriginal,
            activeStep: 0,
            activeTitle: menuItemsData[0].menuTitle,
            activeIcon: menuItemsData[0].icon,
            message: null,
            isCurrentComplete: false,
            notificationOpen: false,
            newLedger: false,
            openModal: false,
            openModalUpload: false,
            beforeChangeStepFunc: [],
            shareEnabled: false,
            hiddenItems: [],
            csvUploading: false
        };
    }

    handleNavigation = navFunc => {
        const nextStep =
            this.state.currentFormChanged
                ? () =>
                    this.handleShowSaveConfirmationModal(
                        () => {
                            this.save && this.save();
                            navFunc()
                        },
                        navFunc)
                : navFunc;
        nextStep();
    };

    handleGoHome = () =>
        this.handleNavigation(() => window.location.href = '/');

    handleAddNewBottle = () =>
        this.handleNavigation(() => window.location.href = '/#/wineSearch');

    handleMenuNavigation = menuItemIndex =>
        this.handleNavigation(() => this.doMenuNavigation(menuItemIndex));

    handleFormUpdate = (formName, { data, originalData, saveFunction }) => {
        this.save = saveFunction;
        this.setState({
            changedFormName: formName,
            currentFormChanged: !_.isEqual(data, originalData)
        });
    };

    handleMenu = () => {
        this.setState({ collapsed: !this.state.collapsed })
    };

    isUploading = (uploading) => {
        this.setState({ csvUploading: uploading });
    };

    executeScroll = (item) => {
        const elem = window.document.getElementById(item);
        let isScrolling;

        const scrollFunc = (event) => {
            window.clearTimeout(isScrolling);
            isScrolling = setTimeout(function () {
                console.log('Scrolling has stopped.');
                scrollWindow();
            }, 66);
        }

        const scrollWindow = () => {
            window.removeEventListener('scroll', scrollFunc)
            clearTimeout();
            console.log(window.scrollY)
            window.scrollTo(0, window.scrollY - 150);
        }

        if (elem) {
            elem.scrollIntoView({ "block": "start", "behavior": "smooth" });
            window.addEventListener('scroll', scrollFunc, false);

            // window.setTimeout(() => {
            //     window.scrollTo(0, window.scrollY - 150)
            // }, 10000);
            // clearTimeout();
        }
    }

    doMenuNavigation = menuItemIndex => {
        const menuItem = this.state.menuItemsData[menuItemIndex];
        const message = this.state.currentLedger ?
            'Please be sure to fill out the entire form before moving on.' :
            'You need to select a Ledger.';

        this.setState({
            activeStep: menuItemIndex,
            activeTitle: menuItem.title
                ? menuItem.title
                : menuItem.menuTitle,
            activeIcon: menuItem.icon,
            message: message,
            isCurrentComplete: false
        });

        this.executeScroll(menuItem.menuTitle);
    };

    closeSaveConfirmationModal = () =>
        this.setState({ isSaveConfirmationModalOpen: false });

    handleShowSaveConfirmationModal = (saveCallback, dontSaveCallback, cancelSaveCallback) => {
        this.handleSave = () => {
            saveCallback && saveCallback();
            this.closeSaveConfirmationModal();
        };
        this.handleDontSave = () => {
            dontSaveCallback && dontSaveCallback();
            this.closeSaveConfirmationModal();
        };
        this.handleCancelSave = () => {
            cancelSaveCallback && cancelSaveCallback();
            this.closeSaveConfirmationModal();
        };
        this.setState({ isSaveConfirmationModalOpen: true });
    };

    handleSelectLedger = (currentLedger) => {
        const menuItem = this.state.menuItemsData[1];
        const menuItemsData = buildMenu(currentLedger);
        const pattern = new RegExp(/^1 /);
        if (pattern.test(currentLedger.wineName)) {
            currentLedger.wineName = currentLedger.wineName.replace(pattern, "NV ");
        }

        this.setState({
            activeStep: 1,
            activeTitle: menuItem.title ? menuItem.title : menuItem.menuTitle,
            activeIcon: menuItem.icon,
            currentLedger: currentLedger,
            menuItemsData,
            message: 'Please be sure to fill out the entire form before moving on.'
        });
    };

    updateLedgerInfo = (currentLedger) => {
        this.setState({
            currentLedger
        });
    };

    handleMessage = (message) => {
        this.setState({ message: message });
    };

    handleComplete = (step) => {
        const menuItemsData = [...this.state.menuItemsData];

        if (!menuItemsData[step].completed) {
            menuItemsData[step].completed = true;
            this.setState({ menuItemsData, isCurrentComplete: true });
        }
    };

    handleIncomplete = (step) => {
        const menuItemsData = [...this.state.menuItemsData];

        if (menuItemsData[step].completed) {
            menuItemsData[step].completed = false;
            this.setState({ menuItemsData, isCurrentComplete: false });
        }
    };

    handleNextStep = (nextStep = null) => {
        let { activeStep, menuItemsData } = this.state;
        this.doMenuNavigation(
            nextStep ||
            ++activeStep % menuItemsData.length + ~~(activeStep / menuItemsData.length));
    };

    handleEnableShare = (value) => {
        this.setState({ shareEnabled: value });
    };

    showNotification = (message) => {
        if (message) {
            this.setState({ notificationOpen: true, notificationMessage: message });

            setTimeout(() => {
                this.setState({ notificationOpen: false });
            }, 5000);
        } else {
            const token = localStorage.getItem('token');
            if (!token) {
                this.setState({
                    notificationOpen: true,
                    notificationMessage: 'Your session has expired'
                });

                setTimeout(() => {
                    this.setState({ notificationOpen: false });
                }, 5000);
            }
        }
    };

    openModal = () => {
        this.setState({ openModal: true });
    };

    closeModal = () => {
        this.setState({ openModal: false });
    };

    openModalUpload = () => {
        this.setState({ openModalUpload: true });
    };

    closeModalUplaod = () => {
        if (!this.state.csvUploading) {
            this.setState({ openModalUpload: false });
        } else {
            this.showNotification(
                'Uploading still in progress. ' +
                'Please, wait until is finished to proceed closing the modal.');
        }
    };

    renderModalBlockchain = () => {
        const { openModal } = this.state;
        const { classes } = this.props;

        return (
            <Dialog
                open={openModal}
                onClose={this.closeModal}
                classes={{
                    paper: classes.dialogRoot
                }}
            >
                <DialogContent>
                    <ChaiBlockchainUser showNotification={this.showNotification} />
                </DialogContent>
                <DialogActions style={{ margin: 0, padding: '0px 20px 20px 0px' }}>
                    <ChaiHeaderButton label="Close" onClick={this.closeModal} />
                </DialogActions>
            </Dialog>
        )
    };

    renderModalUpload = () => {
        const { openModalUpload } = this.state;
        const { classes } = this.props;

        return (
            <Dialog
                open={openModalUpload}
                onClose={this.closeModalUplaod}
                classes={{
                    paper: classes.dialogRootUpload
                }}
            >
                <DialogContent>
                    <ChaiUploadSection
                        showNotification={this.showNotification}
                        isUploading={this.isUploading} />
                </DialogContent>
                <DialogActions style={{ margin: 0, padding: '0px 20px 20px 0px' }}>
                    <ChaiHeaderButton label="Close" onClick={this.closeModalUplaod} />
                </DialogActions>
            </Dialog>
        )
    };

    handleHideItem = (name, hide, items, itemsHidden) => {
        const { menuItemsDataOriginal } = this.state;

        let menuItems = items || this.state.menuItemsData;
        let hideItem = menuItemsDataOriginal.find(item => item.menuTitle === name);
        let hiddenItems = itemsHidden || this.state.hiddenItems;

        if (hide && hideItem) {
            hideItem.position = menuItemsDataOriginal.findIndex(item => item.menuTitle === name);

            menuItems = menuItemsDataOriginal.filter(item => item.menuTitle !== name);
            hiddenItems = [...hiddenItems, hideItem];

            this.setState({ menuItemsData: menuItems, hiddenItems });
        } else if (hideItem && !hide) {
            menuItems = [...menuItemsDataOriginal];
            menuItems.splice(hideItem.position, 0, hideItem);
            this.setState({ menuItemsData: menuItems, hiddenItems });
        }
    };

    isRouteException() {
        return routeExceptions.find(route => route.test(window.location.href))
    }

    renderSaveConfirmationModal = () => (
        <ChaiModal
            open={this.state.isSaveConfirmationModalOpen}
            onClose={this.handleCancelSave}
            title="You have unsaved changes in this screen. Would you like to save them?"
            content=""
            footerButtons={(
                <div>
                    <ChaiHeaderButton
                        style={{ marginRight: '5px' }}
                        label="Cancel"
                        onClick={this.handleCancelSave}
                        secondary
                    />
                    <ChaiHeaderButton
                        style={{ marginRight: '5px' }}
                        label="Don't save"
                        onClick={this.handleDontSave}
                        secondary
                    />
                    <ChaiHeaderButton
                        style={{ marginRight: '5px' }}
                        label="Save"
                        onClick={this.handleSave}
                    />
                </div>
            )}
            width='md'
        />);

    render() {
        const { classes } = this.props;
        const { collapsed, menuItemsData, activeStep, activeTitle, activeIcon,
            notificationOpen, notificationMessage, currentLedger, shareEnabled, hiddenItems
        } = this.state;

        let path = menuItemsData[activeStep].path;

        const stepperData = {
            steps: menuItemsData,
            activeStep,
            hiddenItems
        };

        let logged = localStorage.getItem('token');
        return (
            <MuiThemeProvider theme={chaiTheme}>
                <Router>
                    <div>
                        {
                            currentLedger
                                ? <Redirect to={path} />
                                : this.isRouteException()
                                    ? null
                                    : <Redirect to='/' />
                        }
                        <div>
                            {logged && <ChaiHeader
                                handleMenu={this.handleMenu}
                                menuTitle={activeTitle}
                                iconTitle={activeIcon}
                                collapsed={collapsed}
                                showNotification={this.showNotification}
                                currentLedger={this.state.currentLedger}
                                handleSelectLedger={this.handleSelectLedger}
                                openModal={this.openModal}
                                openModalUpload={this.openModalUpload}
                                handleEnableShare={this.handleEnableShare}
                                shareEnabled={shareEnabled}
                                updateLedgerInfo={this.updateLedgerInfo}
                                handleAddNewBottle={this.handleAddNewBottle}
                                handleGoHome={this.handleGoHome}
                            />}
                        </div>
                        <div>
                            <div className={classes.container}>
                                <ChaiContainer
                                    shareEnabled={shareEnabled}
                                    handleEnableShare={this.handleEnableShare}
                                    handleComplete={this.handleComplete}
                                    handleIncomplete={this.handleIncomplete}
                                    handleSelectLedger={this.handleSelectLedger}
                                    handleMenuNavigation={this.handleMenuNavigation}
                                    handleNextStep={this.handleNextStep}
                                    handleStep={this.handleStep}
                                    updateLedgerInfo={this.updateLedgerInfo}
                                    logged={logged}
                                    showNotification={this.showNotification}
                                    {...stepperData}
                                    currentLedger={currentLedger}
                                    handleHideItem={this.handleHideItem}
                                    handleFormUpdate={this.handleFormUpdate}
                                    changedFormName={this.state.changedFormName}
                                    currentFormChanged={this.state.currentFormChanged}
                                />
                            </div>
                            <ChaiNotification
                                message={notificationMessage}
                                open={notificationOpen} />
                            {this.renderModalBlockchain()}
                            {this.renderModalUpload()}
                            {this.renderSaveConfirmationModal()}
                        </div>
                    </div>
                </Router>
            </MuiThemeProvider>
        );
    };
}

ChaiMain.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(ChaiMain);
