import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import classNames from 'classnames';
import md from 'markdown';
import sha512 from 'js-sha512';

import { Add, Remove } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';

import ChaiAdministrativeColumn from '../ChaiAdministrativeColumn';
import ChaiBaseForm from '../ChaiBaseForm';
import ChaiBodyContainer from '../ChaiBodyContainer';
import ChaiDropDown from '../ChaiDropDown';
import ChaiDropDownMultiple from '../ChaiDropDownMultiple';
import ChaiFooterContainer from '../ChaiFooterContainer';
import ChaiHeaderContainer from '../ChaiHeaderContainer';
import ChaiImageInput from '../ChaiImageInput';
import ChaiStepper from '../ChaiStepper';
import ChaiRadioButton from '../ChaiRadioButton';
import ChaiRowBodyContainer from '../ChaiRowBodyContainer';
import ChaiSwitch from '../ChaiSwitch';
import ChaiTextField from '../ChaiTextField';

import ChaiAntifraud from '../ChaiAntifraud';
import ChaiAntifraudColumn from '../ChaiAntifraudColumn';
import ChaiAuthenticator from '../ChaiAuthenticator';
import ChaiAuthenticatorColumn from '../ChaiAuthenticatorColumn';
import ChaiBottleData from '../ChaiBottleData';
import ChaiBottleDataColumn from '../ChaiBottleDataColumn';
import ChaiBottleInformation from '../ChaiBottleInformationClassic';
import ChaiBottleInformationColumn from '../ChaiBottleInformationColumn';
import ChaiCorkColumn from '../ChaiCorkColumn';
import ChaiCounterfeit from '../ChaiCounterfeit';
import ChaiClosure from '../ChaiClosure';
import ChaiGlass from '../ChaiGlass';
import ChaiMainLabel from '../ChaiMainLabel';
import ChaiMeasurements from '../ChaiMeasurements';
import ChaiOtherLabel from '../ChaiOtherLabel';
import ChaiOtherLabelColumn from '../ChaiOtherLabelColumn';

import CapsuleForm from '../ChaiClosure/CapsuleForm'
import CorkForm from '../ChaiClosure/CorkForm';

import ChaiHeaderButton from '../ChaiHeaderButton';
import ChaiPaginator from '../ChaiPaginator';

import { createLedgers } from './service';

import formNames from '../ChaiBaseForm/formNames';

import {
    INSPECTION_LOCATION,
    INSPECTION_WITNESSES,
    INSPECTION_COMPANY,
    BOTTLES_PER_PAGE,
    MAX_NUM_BOTTLES
} from '../../constants';

import { mainMenuItemsData, secondaryMenuItemsData } from './mainMultiBottleMenuItemsData';

const fieldHeight = 66;
const styles = theme => ({
    line: {
        borderBottom: '1px solid #9c9c9c'
    },
    field: {
        display: 'flex',
        height: `${fieldHeight}px`,
        position: 'relative'
    },
    fieldBody: {
        alignItems: 'center',
        display: 'inline-flex',
        margin: '0 auto'
    },
    fieldLabel: {
        lineHeight: `${fieldHeight}px`
    },
    fieldSubLabel: {
        color: '#8a8a8a',
        left: '0',
        position: 'absolute',
        top: '42px'
    },
    height1t: {
        height: `${fieldHeight}px`
    },
    height2t: {
        height: `${fieldHeight * 2}px`
    },
    height3t: {
        height: `${fieldHeight * 3}px`
    },
    height4t: {
        height: `${fieldHeight * 4}px`
    },
    height5t: {
        height: `${fieldHeight * 5}px`
    },
    section: {
        marginTop: '64px'
    },
    sectionTitle: {
        borderBottom: 'solid 1px black',
        color: '#632222',
        cursor: 'pointer',
        fontWeight: 'normal',
        paddingBottom: '8px'
    },
    sectionBody: {
        transition: '200ms'
    },
    collapsed: {
        height: '0',
        opacity: '0',
        overflow: 'hidden'
    }
});

class ChaiMultiBottleEntry extends ChaiBaseForm {
    currentUser = JSON.parse(localStorage.user);

    componentDidMount = () => {
        super.componentDidMount();
        const { args } = this.props;
        if (args) {
            const _case = {
                caseInformation: args.caseInformation,
                id: args.caseId,
                image: args.caseImage,
                numberOfBottles: parseInt(args.caseNumberOfBottles),
                qrCode: args.caseQrCode,
                rfidCode: args.caseRfidCode
            };
            const bottles = [...Array(_case.numberOfBottles).keys()].map((x, i) => ({}));
            const totalBottlePages =
                ~~(_case.numberOfBottles / BOTTLES_PER_PAGE) + 1 -
                ~~(((_case.numberOfBottles - 1) % BOTTLES_PER_PAGE + 1) / BOTTLES_PER_PAGE);
            this.setState({
                bottleImageToUse: 'front',
                bottles,
                bottlesPage: 1,
                case: _case,
                multiBottlePage: 1,
                totalBottlePages
            });
        }
    };

    handleAddBottle = evt => {
        const { bottles } = this.state;
        if (bottles.length < MAX_NUM_BOTTLES) {
            bottles.push({});
            this.setState({ bottles });
        }
    };

    handleBack = evt => {
        this.setState({ multiBottlePage: 1 });
    };

    handleCloseCreateLedgersModal = () =>
        this.setState({ isCreateLedgersModalOpen: false });

    handleCreateLedgers = async evt => {
        const { showNotification } = this.props;

        this.setState({ creatingLedgers: true, errors: null, ledgerNumbers: null });

        showNotification("Creating ledgers...");

        let request = {
            bottles: this.state.bottles,
            case: {...this.state.case, id: this.state.case.caseId || null},
            ...this.state.formsData,
            password: sha512(this.state.password),
            userAddress: this.currentUser.address
        };
        request.mainLabel.waterMarkLocationRef =  {
            location: request.mainLabel.waterMarkLocation,
            description: request.mainLabel.waterMarkDescription
        };
        request.mainLabel.stampMarkings = request.mainLabel.stampsMarkings;
        request.mainLabel.stampMarkingsLocationRef = {
            location: request.mainLabel.stampsMarkingsLocation,
            description: request.mainLabel.stampsMarkingsDescription
        };
        request.mainLabel.labelEmbossingLocationRef = {
            location: request.mainLabel.labelEmbossingLocation,
            description: request.mainLabel.labelEmbossingDescription
        };
        request.mainLabel.otherLabelLocationRef = {
            location: request.mainLabel.otherLabelLocation,
            description: request.mainLabel.otherLabelDescription
        };
        for (const i in request.bottles) {
            const { authenticator, closureCork } = request.bottles[i];
            const {
                bin,
                bottleConditionSummary,
                boxNumber,
                locker,
                shelf,
                typeOfStorage
            } = authenticator;

            request.bottles[i].bottleData.isBottleImageFront =
                this.state.bottleImageToUse === 'front';

            request.bottles[i].closureCork.corkBrandMarkInfo = {
                description: closureCork.corkBrandDescription,
                image: closureCork.corkBrandImage
            };

            request.bottles[i].closureCork.vintageLegibleInfo = {
                description: closureCork.vintageLegibleDescription,
                image: closureCork.vintageLegibleImage
            };
            delete request.bottles[i].closureCork.corkType;

            request.bottles[i].authenticator.bottleConditionSummary = bottleConditionSummary;
            request.bottles[i].authenticator.wineLocation =
                { bin, boxNumber, locker, shelf, typeOfStorage };

            request.bottles[i].otherLabel.otherNeckDescription =
                request.bottles[i].otherLabel.otherNeckLabelDescription;
            delete request.bottles[i].otherLabel.otherNeckLabelDescription;
        }
        try {
            const ledgerNumbers = await createLedgers(request);
            this.setState({ ledgerNumbers });
            showNotification("Successfully created the ledgers.");
        } catch(error) {
            showNotification("Some errors were found");
            this.setState({
                errors: error.content.content
                    ? error.content.content
                    : 'An unexpected error has occurred. Please try again later.'
            });
        } finally {
            this.setState({ creatingLedgers: false });
        }
    };

    handleAuthenticatorFormUpdate = (formName, { data }) => {
        this.handleFormUpdate(formName, { data: _.cloneDeep(data) });
        localStorage[INSPECTION_LOCATION] = data.inspectionLocation || '';
        localStorage[INSPECTION_WITNESSES] = data.peoplePresentAssistanceInspection || '';
        localStorage[INSPECTION_COMPANY] = data.company || '';
    };


    handleMultiBottleFormUpdate = (formName, { data, index }) => {
        const bottles = _.cloneDeep(this.state.bottles);
        bottles[index][formName] = _.cloneDeep(data);
        this.setState({ bottles });
    }

    handleSelectTemplate = selectedTemplate => {
        // Stamps Markings name inconsistency fix
        selectedTemplate.stampsMarkings = selectedTemplate.stampMarkings;
        selectedTemplate.stampsMarkingsDescription =
            selectedTemplate.stampMarkingsLocationRef.Description;
        selectedTemplate.stampsMarkingsLocation =
            selectedTemplate.stampMarkingsLocationRef.Location;

        // Watermark name inconsistency fix
        const waterMarkLocationRef = selectedTemplate.waterMarkLocationRef || {};
        selectedTemplate.waterMarkDescription = waterMarkLocationRef.Description;
        selectedTemplate.waterMarkLocation = waterMarkLocationRef.Location;

        // Label Embossing name inconsistency fix
        const labelEmbossingLocationRef = selectedTemplate.waterMarkLocationRef || {};
        selectedTemplate.labelEmbossingDescription = labelEmbossingLocationRef.Description;
        selectedTemplate.labelEmbossingLocation = labelEmbossingLocationRef.Location;

        // Other Label name inconsistency fix
        const otherLabelLocationRef = selectedTemplate.otherLabelLocationRef || {};
        selectedTemplate.otherLabelDescription = otherLabelLocationRef.Description;
        selectedTemplate.otherLabelLocation = otherLabelLocationRef.Location;

        this.setState({ selectedTemplate });
        const { bottles, formsData } = this.state;
        Object.keys(selectedTemplate).forEach(x => {
            const value = selectedTemplate[x];
            Object.keys(formsData).forEach(y => {
                if (formsData[y][x] !== undefined) {
                    formsData[y][x] = value;
                }
            });
        });
    };

    handleNext = evt => {
        this.setState({ multiBottlePage: 2 });
    };

    handleCopyBottle = index => {
        const { bottles } = this.state;
        let clippy = _.cloneDeep(bottles[index]);
        delete clippy.administrative.qrCode;
        delete clippy.administrative.rfidCode;
        delete clippy.bottleData.fullBottleImageFront;
        delete clippy.bottleData.fullBottleImageBack;
        delete clippy.bottleData.serialNumber;
        delete clippy.bottleInformation.barCode;
        delete clippy.closureCapsule.capsuleSideImage;
        delete clippy.closureCapsule.capsuleTopImage;
        this.setState({ clippy, clippyBottleIndex: index });
    };

    handlePasteBottle = index => {
        const bottles = _.cloneDeep(this.state.bottles);
        const clippy = _.cloneDeep(this.state.clippy);
        bottles[index] = _.merge(bottles[index], clippy);
        this.setState({ bottles, clippy: null });
    };

    handlePasteToAllBottles = () => {
        const { clippyBottleIndex } = this.state;
        const bottles = _.cloneDeep(this.state.bottles);
        const clippy = _.cloneDeep(this.state.clippy);
        const newBottles = bottles
            .map((x, i) =>
                i === clippyBottleIndex
                    ? x
                    : _.merge(x, _.cloneDeep(clippy)));
        this.setState({ bottles: newBottles, clippy: null });
    };

    handleRemoveBottle = index => {
        const bottles = this.state.bottles.filter((x, i) => i != index);
        this.setState({ bottles });
    };

    handleChangeBottleImageToUse = evt =>
        this.setState({ bottleImageToUse: evt.target.value });

    renderPaginator() {
        const { bottlesPage, totalBottlePages } = this.state;
        const numberOfBottles = (this.state.case || {}).numberOfBottles || 1;

        return (
            <ChaiPaginator
                currentPage={bottlesPage}
                margin="16px"
                onNextPageClick={() =>
                    this.setState({ bottlesPage: bottlesPage + 1 })}
                onPageClick={x => this.setState({ bottlesPage: x })}
                onPreviousPageClick={() =>
                    this.setState({ bottlesPage: bottlesPage - 1 })}
                textAlign="center"
                totalPages={totalBottlePages}
            />
        );
    }

    render() {
        const { classes, currentLedger } = this.props;
        const {
            // Common sections
            collapseSectionAuthenticator,
            collapseSectionBottleInformation,
            collapseSectionCounterfeit,
            collapseSectionMeasurements,
            collapseSectionMainLabel,
            collapseSectionOtherLabel,
            collapseSectionClosureCapsule,
            collapseSectionClosureCork,
            collapseSectionGlass,
            collapseSectionAntifraud,
            // Single bottle sections
            collapseSectionAdministrativeSingle,
            collapseSectionAntifraudSingle,
            collapseSectionAuthenticatorSingle,
            collapseSectionBottleDataSingle,
            collapseSectionClosureCorkSingle,
            collapseSectionOtherLabelSingle,
            // Other state variables
            bottlesPage,
            clippy,
            multiBottlePage,
            totalBottlePages
        } = this.state;

        const bottles = this.state.bottles || [];
        const bottlesPageStart = (bottlesPage - 1) * BOTTLES_PER_PAGE;
        const bottlesPageEnd = bottlesPageStart + BOTTLES_PER_PAGE;
        const numberOfBottles = (this.state.case || {}).numberOfBottles || 1;

        const closureCapsule = (this.state.formsData || {}).closureCapsule || {};

        const currentDateString = new Date().toISOString();

        const footerData = {
            message: 'Please be sure to fill out the entire form before moving on.',
            buttonLabel: 'Save & Next',
            loading: this.state.loading
        };

        return (
            <Fragment>
                <ChaiHeaderContainer {...currentLedger} mode='multiBottleEntry' />
                <ChaiStepper
                    {...this.props}
                    handleMenuNavigation={() => { }}
                    steps={
                        multiBottlePage === 1
                            ? mainMenuItemsData
                            : secondaryMenuItemsData
                    } activeStep="1"
                />
                <ChaiBodyContainer>
                    <ChaiRowBodyContainer style={{
                        display: multiBottlePage == 1 ? 'flex' : 'none'
                    }}>
                        <ChaiHeaderButton
                            label="Next"
                            style={{ margin: '0 0 0 auto' }}
                            onClick={this.handleNext}
                        />
                    </ChaiRowBodyContainer>
                    <ChaiRowBodyContainer style={{
                        display: multiBottlePage == 2 ? 'flex' : 'none'
                    }}>
                        <div style={{ margin: '0 0 0 auto' }}>
                            <ChaiHeaderButton
                                disabled={_.isEmpty(clippy)}
                                label="Paste to All"
                                onClick={this.handlePasteToAllBottles}
                                secondary
                                style={{ marginLeft: '10px' }}
                            />
                            <ChaiHeaderButton
                                label="Add Bottle"
                                onClick={this.handleAddBottle}
                                style={{ marginLeft: '10px' }}
                                disabled={bottles.length >= MAX_NUM_BOTTLES}
                            />
                        </div>
                    </ChaiRowBodyContainer>

                    <div style={{ display: multiBottlePage === 1 ? 'block' : 'none' }}>
                        <div className={classes.section}>
                            <h1
                                className={classes.sectionTitle}
                                onClick={() => this.setState({
                                    collapseSectionAuthenticator: !collapseSectionAuthenticator
                                })}
                            >
                                {collapseSectionAuthenticator ? <Add /> : <Remove />}&nbsp;
                                Authenticator
                            </h1>
                            <div className={classNames(
                                classes.sectionBody,
                                collapseSectionAuthenticator ? classes.collapsed : null
                            )}>
                                <ChaiAuthenticator
                                    {...this.props}
                                    data={(this.state.formsData || {}).authenticator}
                                    defaultValues={{
                                        authenticatorCode: this.currentUser.id,
                                        company: localStorage[INSPECTION_COMPANY],
                                        dateOfInspection: currentDateString
                                            .substring(0, currentDateString.indexOf('T')),
                                        inspectionLocation: localStorage[INSPECTION_LOCATION],
                                        ledgerNumber: 0,
                                        nameOfPerson: this.currentUser.name,
                                        peoplePresentAssistanceInspection:
                                            localStorage[INSPECTION_WITNESSES]
                                    }}
                                    handleFormUpdate={this.handleAuthenticatorFormUpdate}
                                    hideFooter
                                />
                            </div>
                        </div>
                        <div className={classes.section}>
                            <h1
                                className={classes.sectionTitle}
                                onClick={() => this.setState({
                                    collapseSectionBottleInformation:
                                        !collapseSectionBottleInformation
                                })}
                            >
                                {collapseSectionBottleInformation ? <Add /> : <Remove />}&nbsp;
                                Bottle Information
                            </h1>
                            <div className={classNames(
                                classes.sectionBody,
                                collapseSectionBottleInformation ? classes.collapsed : null
                            )}>
                                <ChaiBottleInformation
                                    {...this.props}
                                    data={(this.state.formsData || {}).bottleInformation}
                                    defaultValues={this.props.args}
                                    handleFormUpdate={this.handleFormUpdate}
                                    hideFooter
                                    loadOptions
                                    selectTemplate={this.handleSelectTemplate}
                                    template={this.state.selectedTemplate}
                                />
                            </div>
                        </div>
                        {
                            this.state.formsData &&
                            this.state.formsData.bottleInformation &&
                            this.state.formsData.bottleInformation.typeOfInput === 2 &&
                            <div className={classes.section}>
                                <h1
                                    className={classes.sectionTitle}
                                    onClick={() => this.setState({
                                        collapseSectionCounterfeit: !collapseSectionCounterfeit
                                    })}
                                >
                                    {collapseSectionCounterfeit ? <Add /> : <Remove />}&nbsp;
                                    Counterfeit
                                </h1>
                                <div className={classNames(
                                    classes.sectionBody,
                                    collapseSectionCounterfeit ? classes.collapsed : null
                                )}>
                                    <ChaiCounterfeit
                                        {...this.props}
                                        data={(this.state.formsData || {}).counterfeit}
                                        handleFormUpdate={this.handleFormUpdate}
                                        hideFooter
                                        selectTemplate={this.handleSelectTemplate}
                                        template={this.state.selectedTemplate}
                                    />
                                </div>
                            </div>
                        }
                        <div className={classes.section}>
                            <h1
                                className={classes.sectionTitle}
                                onClick={() => this.setState({
                                    collapseSectionMeasurements: !collapseSectionMeasurements
                                })}
                            >
                                {collapseSectionMeasurements ? <Add /> : <Remove />}&nbsp;
                                Measurements
                            </h1>
                            <div className={classNames(
                                classes.sectionBody,
                                collapseSectionMeasurements ? classes.collapsed : null
                            )}>
                                <ChaiMeasurements
                                    {...this.props}
                                    data={(this.state.formsData || {}).measurements}
                                    defaultValues={{
                                        puntDiameter: '7.5'
                                    }}
                                    handleFormUpdate={this.handleFormUpdate}
                                    hideFooter
                                    selectTemplate={this.handleSelectTemplate}
                                    template={this.state.selectedTemplate}
                                />
                            </div>
                        </div>
                        <div className={classes.section}>
                            <h1
                                className={classes.sectionTitle}
                                onClick={() => this.setState({
                                    collapseSectionMainLabel: !collapseSectionMainLabel
                                })}
                            >
                                {collapseSectionMainLabel ? <Add /> : <Remove />}&nbsp;
                                Main Label
                            </h1>
                            <div className={classNames(
                                classes.sectionBody,
                                collapseSectionMainLabel ? classes.collapsed : null
                            )}>
                                <ChaiMainLabel
                                    {...this.props}
                                    data={(this.state.formsData || {}).mainLabel}
                                    defaultValues={{
                                        labelPrintMethodImage: '',
                                        inkColorImage: ''
                                    }}
                                    handleFormUpdate={this.handleFormUpdate}
                                    hideFooter
                                    loadOptions
                                    selectTemplate={this.handleSelectTemplate}
                                    template={this.state.selectedTemplate}
                                />
                            </div>
                        </div>
                        <div className={classes.section}>
                            <h1
                                className={classes.sectionTitle}
                                onClick={() => this.setState({
                                    collapseSectionOtherLabel: !collapseSectionOtherLabel
                                })}
                            >
                                {collapseSectionOtherLabel ? <Add /> : <Remove />}&nbsp;
                                Other Labels
                            </h1>
                            <div className={classNames(
                                classes.sectionBody,
                                collapseSectionOtherLabel ? classes.collapsed : null
                            )}>
                                <ChaiOtherLabel
                                    {...this.props}
                                    data={(this.state.formsData || {}).otherLabel}
                                    handleFormUpdate={this.handleFormUpdate}
                                    hideFooter
                                    selectTemplate={this.handleSelectTemplate}
                                    template={this.state.selectedTemplate}
                                />
                            </div>
                        </div>
                        <div className={classes.section}>
                            <h1
                                className={classes.sectionTitle}
                                onClick={() => this.setState({
                                    collapseSectionClosureCapsule: !collapseSectionClosureCapsule
                                })}
                            >
                                {collapseSectionClosureCapsule ? <Add /> : <Remove />}&nbsp;
                                Closure Capsule
                            </h1>
                            <div className={classNames(
                                classes.sectionBody,
                                collapseSectionClosureCapsule ? classes.collapsed : null
                            )}>
                                <CapsuleForm
                                    {...this.props}
                                    data={(this.state.formsData || {}).closureCapsule}
                                    defaultValues={{
                                        capsuleSideImage: '',
                                        capsuleTopImage: ''
                                    }}
                                    formName="closureCapsule"
                                    handleFormUpdate={this.handleFormUpdate}
                                    hideFooter
                                    loadOptions
                                    selectTemplate={this.handleSelectTemplate}
                                    template={this.state.selectedTemplate}
                                />
                            </div>
                        </div>
                        <div className={classes.section}>
                            <h1
                                className={classes.sectionTitle}
                                onClick={() => this.setState({
                                    collapseSectionClosureCork: !collapseSectionClosureCork
                                })}
                            >
                                {collapseSectionClosureCork ? <Add /> : <Remove />}&nbsp;
                                Closure Cork
                            </h1>
                            <div className={classNames(
                                classes.sectionBody,
                                collapseSectionClosureCork ? classes.collapsed : null
                            )}>
                                <CorkForm
                                    {...this.props}
                                    data={(this.state.formsData || {}).closureCork}
                                    defaultValues={{ corkType: 1 }}
                                    formName="closureCork"
                                    handleFormUpdate={this.handleFormUpdate}
                                    hideFooter
                                    loadOptions
                                    selectTemplate={this.handleSelectTemplate}
                                    template={this.state.selectedTemplate}
                                />
                            </div>
                        </div>
                        <div className={classes.section}>
                            <h1
                                className={classes.sectionTitle}
                                onClick={() => this.setState({
                                    collapseSectionGlass: !collapseSectionGlass
                                })}
                            >
                                {collapseSectionGlass ? <Add /> : <Remove />}&nbsp;
                                Glass
                            </h1>
                            <div className={classNames(
                                classes.sectionBody,
                                collapseSectionGlass ? classes.collapsed : null
                            )}>
                                <ChaiGlass
                                    {...this.props}
                                    data={(this.state.formsData || {}).glass}
                                    handleFormUpdate={this.handleFormUpdate}
                                    hideFooter
                                    loadOptions
                                    selectTemplate={this.handleSelectTemplate}
                                    template={this.state.selectedTemplate}
                                />
                                <ChaiBottleData
                                    {...this.props}
                                    data={(this.state.formsData || {}).bottleData}
                                    handleFormUpdate={this.handleFormUpdate}
                                    hideFooter
                                    selectTemplate={this.handleSelectTemplate}
                                    template={this.state.selectedTemplate}
                                />
                            </div>
                        </div>
                        <div className={classes.section}>
                            <h1
                                className={classes.sectionTitle}
                                onClick={() => this.setState({
                                    collapseSectionAntifraud: !collapseSectionAntifraud
                                })}
                            >
                                {collapseSectionAntifraud ? <Add /> : <Remove />}&nbsp;
                                Antifraud
                            </h1>
                            <div className={classNames(
                                classes.sectionBody,
                                collapseSectionAntifraud ? classes.collapsed : null
                            )}>
                                <ChaiAntifraud
                                    {...this.props}
                                    data={(this.state.formsData || {}).antifraud}
                                    handleFormUpdate={this.handleFormUpdate}
                                    hideFooter
                                    selectTemplate={this.handleSelectTemplate}
                                    template={this.state.selectedTemplate}
                                />
                            </div>
                        </div>
                    </div>

                    <div style={{ display: multiBottlePage === 2 ? 'block' : 'none' }}>
                        {this.renderPaginator()}
                        <div className={classes.section}>
                            <h1
                                className={classes.sectionTitle}
                                onClick={() => this.setState({
                                    collapseSectionBottleDataSingle:
                                        !collapseSectionBottleDataSingle
                                })}
                            >
                                {collapseSectionBottleDataSingle ? <Add /> : <Remove />}&nbsp;
                                Bottle Data
                            </h1>
                            <div
                                className={classNames(
                                    classes.sectionBody,
                                    collapseSectionBottleDataSingle ? classes.collapsed : null
                                )}
                                style={{ display: 'flex' }}
                            >
                                <div style={{
                                    display: 'inline-block',
                                    flex: 'none',
                                    verticalAlign: 'top',
                                    width: '301px'
                                }}>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            FILL LEVEL*
                                        </label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}>
                                            OPTIONS*
                                        </label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}>
                                            LABEL CONDITIONS FOR AGE*
                                    </label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}>
                                            CORK CONDITION*
                                    </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            CAPSULE CONDITION*
                                        </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            DETAILS*
                                        </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}></label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}></label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}>
                                            VINTAGE LEGIBLE
                                        </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>SEDIMENT*</label>
                                        <label className={classes.fieldSubLabel}>
                                            Select all that apply
                                        </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>&nbsp;</label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}>&nbsp;</label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            <ChaiRadioButton
                                                checked={this.state.bottleImageToUse === 'front'}
                                                label="Use Front Image*"
                                                onChange={this.handleChangeBottleImageToUse}
                                                value="front"
                                            />
                                        </label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}>
                                            <ChaiRadioButton
                                                checked={this.state.bottleImageToUse !== 'front'}
                                                label="Use Back Image*"
                                                onChange={this.handleChangeBottleImageToUse}
                                                value="back"
                                            />
                                        </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            HAS SERIAL NUMBER
                                    </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            SERIAL NUMBER
                                    </label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}>
                                            SERIAL LOCATION
                                    </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>HAS LOT NUMBER</label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>LOT NUMBER</label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}>LOT LOCATION</label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            NOTABLE NICKS / TEARS / CONDITIONS
                                    </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            NICKS LOCATION
                                    </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            NICKS DESCRIPTION
                                    </label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}>
                                            NICKS IMAGE
                                    </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            OTHER LABELS CONDITIONS
                                    </label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}></label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            CAPSULE CONDITION
                                    </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}></label>
                                    </div>
                                </div>
                                {
                                    bottles.map((x, i) => {
                                        if (i >= bottlesPageStart && i < bottlesPageEnd) {
                                            const style = {
                                                color: '#632222',
                                                display: 'inline-block',
                                                flexGrow: '1',
                                                marginTop: '-118px',
                                                maxWidth: '224px',
                                                padding: '8px 0',
                                                position: 'relative',
                                                verticalAlign: 'top'
                                            };
                                            if (i % 2 === 0) {
                                                style.backgroundColor = 'rgba(0, 0, 0, .15)';
                                            }
                                            return (
                                                <div style={style}>
                                                    <label style={{
                                                        display: 'inline-block',
                                                        fontSize: '24px',
                                                        lineHeight: '110px',
                                                        position: 'relative',
                                                        textAlign: 'center',
                                                        width: '100%'
                                                    }}>
                                                        <div style={{
                                                            left: '0px',
                                                            position: 'absolute',
                                                            right: '4px',
                                                            textAlign: 'center',
                                                            top: '-36px'
                                                        }}>
                                                            <a
                                                                onClick={() => this.handleCopyBottle(i)}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                <svg
                                                                    style={{ fill: '#632222' }}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                ><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>
                                                            </a>
                                                            <a
                                                                disabled={_.isEmpty(clippy)}
                                                                onClick={
                                                                    () => this.handlePasteBottle(i)
                                                                }
                                                                style={{
                                                                    cursor: _.isEmpty(clippy)
                                                                        ? 'default'
                                                                        : 'pointer'
                                                                }}>
                                                                <svg
                                                                    style={{
                                                                        fill: _.isEmpty(clippy)
                                                                            ? '#666'
                                                                            : '#632222'
                                                                    }}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                ><path d="M19 2h-4.18C14.4.84 13.3 0 12 0c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm7 18H5V4h2v3h10V4h2v16z"/></svg>
                                                            </a>
                                                            &nbsp;
                                                        </div>
                                                        Bottle {i + 1}
                                                        {i !== 0 && <a
                                                            onClick={() => this.handleRemoveBottle(i)}
                                                            style={{
                                                                cursor: 'pointer',
                                                                position: 'absolute',
                                                                right: '36px',
                                                                top: '4px',
                                                                verticalAlign: 'middle'
                                                            }}
                                                        ><svg
                                                            style={{ fill: '#632222' }}
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                        ><path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>
                                                        </a>}
                                                    </label>
                                                    <ChaiBottleDataColumn
                                                        {...this.props}
                                                        data={bottles[i].bottleData}
                                                        defaultValues={{
                                                            serialNumber: '',
                                                            serialNumberLocation: '',
                                                            lotNumber: '',
                                                            lotNumberLocation: '',
                                                            notableNicksLocation: '',
                                                            notableNicksDescription: '',
                                                            otherLabelsConditionsDescription: '',
                                                            capsuleOtherDescription: '',
                                                            glassMarkingsDescription: ''
                                                        }}
                                                        key={i}
                                                        index={i}
                                                        handleFormUpdate={this.handleMultiBottleFormUpdate}
                                                        hideFooter
                                                        loadOptions
                                                    />
                                                </div>
                                            );
                                        }
                                    })
                                }
                            </div>
                        </div>
                        <div className={classes.section}>
                            <h1
                                className={classes.sectionTitle}
                                onClick={() => this.setState({
                                    collapseSectionOtherLabelSingle:
                                        !collapseSectionOtherLabelSingle
                                })}
                            >
                                {collapseSectionOtherLabelSingle ? <Add /> : <Remove />}&nbsp;
                                Other Labels
                            </h1>
                            <div
                                className={classNames(
                                    classes.sectionBody,
                                    collapseSectionOtherLabelSingle ? classes.collapsed : null
                                )}
                                style={{ display: 'flex' }}
                            >
                                <div classNames="labels" style={{
                                    display: 'inline-block',
                                    flex: 'none',
                                    verticalAlign: 'top',
                                    width: '301px'
                                }}>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            IMPORT LABEL
                                        </label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}></label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            OTHER BACK LABEL
                                    </label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}></label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            OTHER NECK LABEL
                                    </label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}></label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            OTHER LABEL 1
                                    </label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}></label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            OTHER LABEL 2
                                    </label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}></label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            RETAIL MERCHANT OR AUCTION 1
                                    </label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}></label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            RETAIL MERCHANT OR AUCTION 2
                                    </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}></label>
                                    </div>
                                </div>
                                {
                                    bottles.map((x, i) => {
                                        if (i >= bottlesPageStart && i < bottlesPageEnd) {
                                            const style = {
                                                color: '#632222',
                                                display: 'inline-block',
                                                flexGrow: '1',
                                                marginTop: '-118px',
                                                maxWidth: '224px',
                                                padding: '8px 0',
                                                verticalAlign: 'top'
                                            };
                                            if (i % 2 === 0) {
                                                style.backgroundColor = 'rgba(0, 0, 0, .15)';
                                            }
                                            return (
                                                <div style={style}>
                                                    <label style={{
                                                        display: 'inline-block',
                                                        fontSize: '24px',
                                                        lineHeight: '110px',
                                                        textAlign: 'center',
                                                        width: '100%'
                                                    }}>Bottle {i + 1}</label>
                                                    <ChaiOtherLabelColumn
                                                        {...this.props}
                                                        data={bottles[i].otherLabel}
                                                        key={i}
                                                        index={i}
                                                        handleFormUpdate={this.handleMultiBottleFormUpdate}
                                                    />
                                                </div>
                                            );
                                        }
                                    })
                                }
                            </div>
                        </div>
                        <div className={classes.section}>
                            <h1
                                className={classes.sectionTitle}
                                onClick={() => this.setState({
                                    collapseSectionClosureCorkSingle:
                                        !collapseSectionClosureCorkSingle
                                })}
                            >
                                {collapseSectionClosureCorkSingle ? <Add /> : <Remove />}&nbsp;
                                Closure
                            </h1>
                            <div
                                className={classNames(
                                    classes.sectionBody,
                                    collapseSectionClosureCorkSingle ? classes.collapsed : null
                                )}
                                style={{ display: 'flex' }}
                            >
                                <div classNames="labels" style={{
                                    display: 'inline-block',
                                    flex: 'none',
                                    verticalAlign: 'top',
                                    width: '301px'
                                }}>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}>
                                            CAPSULE SIDE IMAGE
                                        </label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}>
                                            CAPSULE TOP IMAGE
                                        </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            CORK BRAND MARK LEGIBLE
                                        </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            CORK BRAND MARK DESCRIPTION
                                        </label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}>
                                            CORK BRAND MARK IMAGE
                                        </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            VINTAGE LEGIBLE
                                        </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            VINTAGE LEGIBLE DESCRIPTION
                                        </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            VINTAGE LEGIBLE IMAGE
                                        </label>
                                    </div>
                                </div>
                                {
                                    bottles.map((x, i) => {
                                        if (i >= bottlesPageStart && i < bottlesPageEnd) {
                                            const style = {
                                                color: '#632222',
                                                display: 'inline-block',
                                                flexGrow: '1',
                                                marginTop: '-118px',
                                                maxWidth: '224px',
                                                padding: '8px 0',
                                                verticalAlign: 'top'
                                            };
                                            if (i % 2 === 0) {
                                                style.backgroundColor = 'rgba(0, 0, 0, .15)';
                                            }
                                            return (
                                                <div style={style}>
                                                    <label style={{
                                                        display: 'inline-block',
                                                        fontSize: '24px',
                                                        lineHeight: '110px',
                                                        textAlign: 'center',
                                                        width: '100%'
                                                    }}>Bottle {i + 1}</label>
                                                    <CapsuleForm
                                                        {...this.props}
                                                        columnView
                                                        data={_.merge(
                                                            bottles[i].closureCapsule,
                                                            {
                                                                capsuleAppliedBy:
                                                                    closureCapsule.capsuleAppliedBy,
                                                                capsuleMaterial:
                                                                    closureCapsule.capsuleMaterial
                                                            })}
                                                        defaultValues={{
                                                            capsuleSideImage: ''
                                                        }}
                                                        formName="closureCapsule"
                                                        key={i}
                                                        index={i}
                                                        handleFormUpdate={
                                                            this.handleMultiBottleFormUpdate
                                                        }
                                                    />
                                                    <ChaiCorkColumn
                                                        {...this.props}
                                                        data={bottles[i].closureCork}
                                                        defaultValues={{
                                                            corkBrandDescription: '',
                                                            vintageLegibleDescription: ''
                                                        }}
                                                        formName="closureCork"
                                                        key={i}
                                                        index={i}
                                                        handleFormUpdate={
                                                            this.handleMultiBottleFormUpdate
                                                        }
                                                        hideFooter
                                                    />
                                                </div>
                                            );
                                        }
                                    })
                                }
                            </div>
                        </div>
                        <div className={classes.section}>
                            <h1
                                className={classes.sectionTitle}
                                onClick={() => this.setState({
                                    collapseSectionAntifraudSingle:
                                        !collapseSectionAntifraudSingle
                                })}
                            >
                                {collapseSectionAntifraudSingle ? <Add /> : <Remove />}&nbsp;
                                Antifraud
                            </h1>
                            <div
                                className={classNames(
                                    classes.sectionBody,
                                    collapseSectionAntifraudSingle ? classes.collapsed : null
                                )}
                                style={{ display: 'flex' }}
                            >
                                <div classNames="labels" style={{
                                    display: 'inline-block',
                                    flex: 'none',
                                    verticalAlign: 'top',
                                    width: '301px'
                                }}>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            GLASS ETCHING/ENGRAVING
                                    </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}></label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}></label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}></label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            QR CODE ON LABEL/CAPSULE/GLASS
                                    </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}></label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}></label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}></label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            PRODUCER BARCODE
                                    </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}></label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}></label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}></label>
                                    </div>
                                </div>
                                {
                                    bottles.map((x, i) => {
                                        if (i >= bottlesPageStart && i < bottlesPageEnd) {
                                            const style = {
                                                color: '#632222',
                                                display: 'inline-block',
                                                flexGrow: '1',
                                                marginTop: '-118px',
                                                maxWidth: '224px',
                                                padding: '8px 0',
                                                verticalAlign: 'top'
                                            };
                                            if (i % 2 === 0) {
                                                style.backgroundColor = 'rgba(0, 0, 0, .15)';
                                            }
                                            return (
                                                <div style={style}>
                                                    <label style={{
                                                        display: 'inline-block',
                                                        fontSize: '24px',
                                                        lineHeight: '110px',
                                                        textAlign: 'center',
                                                        width: '100%'
                                                    }}>Bottle {i + 1}</label>
                                                    <ChaiAntifraudColumn
                                                        {...this.props}
                                                        data={bottles[i].antifraud}
                                                        key={i}
                                                        index={i}
                                                        handleFormUpdate={this.handleMultiBottleFormUpdate}
                                                        hideFooter
                                                    />
                                                </div>
                                            );
                                        }
                                    })
                                }
                            </div>
                        </div>
                        <div className={classes.section}>
                            <h1
                                className={classes.sectionTitle}
                                onClick={() => this.setState({
                                    collapseSectionAdministrativeSingle:
                                        !collapseSectionAdministrativeSingle
                                })}
                            >
                                {collapseSectionAdministrativeSingle ? <Add /> : <Remove />}&nbsp;
                                Administrative
                            </h1>
                            <div
                                className={classNames(
                                    classes.sectionBody,
                                    collapseSectionAdministrativeSingle ? classes.collapsed : null
                                )}
                                style={{ display: 'flex' }}
                            >
                                <div classNames="labels" style={{
                                    display: 'inline-block',
                                    flex: 'none',
                                    verticalAlign: 'top',
                                    width: '301px'
                                }}>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}>
                                            INVENTORY NUMBER/BARCODE
                                        </label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}>
                                            CHAI VAULT QR CODE*
                                    </label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}>
                                            RFID CODE*
                                        </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            LEDGER VISIBILITY
                                        </label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}></label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            ENCRYPT OWNER NAME*
                                        </label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}>
                                            ENCRYPT OWNER INFORMATION*
                                        </label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}>
                                            CAPSULE BATCH*
                                        </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            DON'T SHOW SELLER INFO
                                        </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            SELLER'S NAME
                                        </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            SELLER'S LINK
                                        </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            SELER'S LOGO
                                        </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            SELECT SELER'S BRAND
                                        </label>
                                    </div>
                                </div>
                                {
                                    bottles.map((x, i) => {
                                        if (i >= bottlesPageStart && i < bottlesPageEnd) {
                                            const style = {
                                                color: '#632222',
                                                display: 'inline-block',
                                                flexGrow: '1',
                                                marginTop: '-118px',
                                                maxWidth: '224px',
                                                padding: '8px 0',
                                                verticalAlign: 'top'
                                            };
                                            if (i % 2 === 0) {
                                                style.backgroundColor = 'rgba(0, 0, 0, .15)';
                                            }
                                            const activeBrand = _.isEmpty(this.currentUser.brands)
                                                ? {
                                                    image: '',
                                                    index: null,
                                                    link: '',
                                                    name: ''
                                                  }
                                                : this.currentUser.brands
                                                    .map((x, i) => ({...x, index: i}))
                                                    .filter(x => x.active)[0];
                                            return (
                                                <div style={style}>
                                                    <label style={{
                                                        display: 'inline-block',
                                                        fontSize: '24px',
                                                        lineHeight: '110px',
                                                        textAlign: 'center',
                                                        width: '100%'
                                                    }}>Bottle {i + 1}</label>
                                                    <ChaiBottleInformationColumn
                                                        {...this.props}
                                                        data={bottles[i].bottleInformation}
                                                        handleFormUpdate={
                                                            this.handleMultiBottleFormUpdate
                                                        }
                                                        key={i}
                                                        index={i}
                                                    />
                                                    <ChaiAdministrativeColumn
                                                        {...this.props}
                                                        data={bottles[i].administrative}
                                                        defaultValues={{
                                                            activeBrand: activeBrand.index,
                                                            capsuleBatch: this.currentUser.capsuleBatch,
                                                            sellerLink: activeBrand.link,
                                                            sellerLogo: activeBrand.image,
                                                            sellerName: activeBrand.name
                                                        }}
                                                        key={i}
                                                        index={i}
                                                        handleFormUpdate={
                                                            this.handleMultiBottleFormUpdate
                                                        }
                                                        hideFooter
                                                    />
                                                </div>
                                            );
                                        }
                                    })
                                }
                            </div>
                        </div>
                        <div className={classes.section}>
                            <h1
                                className={classes.sectionTitle}
                                onClick={() => this.setState({
                                    collapseSectionAuthenticatorSingle:
                                        !collapseSectionAuthenticatorSingle
                                })}
                            >
                                {collapseSectionAuthenticatorSingle ? <Add /> : <Remove />}&nbsp;
                                Wine Location
                            </h1>
                            <div
                                className={classNames(
                                    classes.sectionBody,
                                    collapseSectionAuthenticatorSingle ? classes.collapsed : null
                                )}
                                style={{ display: 'flex' }}
                            >
                                <div classNames="labels" style={{
                                    display: 'inline-block',
                                    flex: 'none',
                                    verticalAlign: 'top',
                                    width: '301px'
                                }}>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            TYPE OF STORAGE
                                        </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            BOX NUMBER OR LOCATION 1
                                        </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            LOCKER/WALL OR LOCATION 2
                                        </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            BIN/COLUMN OR LOCATION 3
                                        </label>
                                    </div>
                                    <div className={classNames(classes.field, classes.line)}>
                                        <label className={classes.fieldLabel}>
                                            SHELF/ROW OR LOCATION 4
                                        </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}>
                                            BOTTLE CONDITION SUMMARY*
                                        </label>
                                    </div>
                                    <div className={classes.field}>
                                        <label className={classes.fieldLabel}></label>
                                    </div>
                                </div>
                                {
                                    bottles.map((x, i) => {
                                        if (i >= bottlesPageStart && i < bottlesPageEnd) {
                                            const style = {
                                                color: '#632222',
                                                display: 'inline-block',
                                                flexGrow: '1',
                                                marginTop: '-118px',
                                                maxWidth: '224px',
                                                padding: '8px 0',
                                                verticalAlign: 'top'
                                            };
                                            if (i % 2 === 0) {
                                                style.backgroundColor = 'rgba(0, 0, 0, .15)';
                                            }
                                            return (
                                                <div style={style}>
                                                    <label style={{
                                                        display: 'inline-block',
                                                        fontSize: '24px',
                                                        lineHeight: '110px',
                                                        textAlign: 'center',
                                                        width: '100%'
                                                    }}>Bottle {i + 1}</label>
                                                    <ChaiAuthenticatorColumn
                                                        {...this.props}
                                                        data={bottles[i].authenticator}
                                                        defaultValues={{ ledgerNumber: 0 }}
                                                        key={i}
                                                        index={i}
                                                        handleFormUpdate={this.handleMultiBottleFormUpdate}
                                                        loadOptions
                                                    />
                                                </div>
                                            );
                                        }
                                    })
                                }
                            </div>
                        </div>
                        {this.renderPaginator()}
                    </div>

                    <ChaiRowBodyContainer style={{
                        display: multiBottlePage == 1 ? 'flex' : 'none'
                    }}>
                        <ChaiHeaderButton
                            label="Next"
                            style={{ margin: '0 0 0 auto' }}
                            onClick={this.handleNext}
                        />
                    </ChaiRowBodyContainer>

                    <ChaiRowBodyContainer style={{
                        display: multiBottlePage == 2 ? 'flex' : 'none'
                    }}>
                        <div style={{ margin: '0 0 0 auto' }}>
                            <ChaiHeaderButton
                                label="Back"
                                onClick={this.handleBack}
                                secondary
                            />
                            <ChaiHeaderButton
                                label="Create Ledgers"
                                onClick={() => this.setState({
                                    errors: null,
                                    isCreateLedgersModalOpen: true
                                })}
                                style={{
                                    backgroundColor: '#fff',
                                    border: '2px solid',
                                    color: '#632222',
                                    marginLeft: '10px'
                                }}
                            />
                        </div>
                    </ChaiRowBodyContainer>
                </ChaiBodyContainer>
                {this.renderCreateLedgersModal()}
            </Fragment>
        );
    }

    renderCreateLedgersModal = () => {
        const {
            bottles,
            creatingLedgers,
            errors,
            isCreateLedgersModalOpen,
            ledgerNumbers,
            password
        } = this.state;
        return (
            <div>
                <Dialog
                    open={isCreateLedgersModalOpen}
                    onClose={this.handleCloseCreateLedgersModal}
                    arial-labelledby="from-dialog-title"
                    scroll={"paper"}
                    maxWidth={"sm"}
                >
                    <DialogTitle>
                        <p style={{ textAlign: 'center', margin: '0' }}>Create multiple bottles</p>
                    </DialogTitle>
                    {
                        _.isEmpty(ledgerNumbers) &&
                        <DialogContent dividers>
                            {creatingLedgers && <LinearProgress />}
                            <DialogContentText style={{ color: '#000', cursor: 'default' }}>
                                <p style={{ textAlign: 'justify' }}>
                                    A total of {(bottles || []).length} ledgers will be created.
                                    To create the ledgers confirm
                                    <strong style={{ color: '#632222' }}> YOUR PASSWORD </strong>
                                    in the field below:
                                </p>
                            </DialogContentText>
                            <ChaiTextField
                                autofocus
                                fullWidth
                                inputProps={{ style: { textTransform: 'uppercase' } }}
                                label="Password"
                                margin="dense"
                                onChange={evt => this.setState({ password: evt.target.value })}
                                value={password}
                                type="password"
                            />
                            {errors && <div dangerouslySetInnerHTML={{
                                __html: md.markdown.toHTML(`### Validation errors:\n${errors}`)
                            }}></div>}
                        </DialogContent>
                    }
                    {
                        !_.isEmpty(ledgerNumbers) &&
                        <DialogContent dividers>
                            <DialogContentText style={{ color: '#000', cursor: 'default' }}>
                                <div dangerouslySetInnerHTML={{
                                    __html: md.markdown.toHTML(
                                        `### The following ledger numbers were created:\n` +
                                        `${ledgerNumbers.data
                                            .map(x => `- ${x}`)
                                            .reduce((x, y) => `${x}\n${y}`)}`)
                                }}>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                    }
                    {
                        _.isEmpty(ledgerNumbers) &&
                        <DialogActions style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                            <ChaiHeaderButton
                                disabled={creatingLedgers}
                                label="Close"
                                onClick={this.handleCloseCreateLedgersModal}
                                secondary
                                value="Close"
                            ></ChaiHeaderButton>
                            <ChaiHeaderButton
                                disabled={!password || creatingLedgers}
                                label="Confirm"
                                onClick={this.handleCreateLedgers}
                                style={{ marginLeft: '10px', }}
                                value="Confirm"
                            ></ChaiHeaderButton>
                        </DialogActions>
                    }
                    {
                        !_.isEmpty(ledgerNumbers) &&
                        <DialogActions style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                            <ChaiHeaderButton
                                label="Add more bottles"
                                onClick={() => window.location.href = '/#/wineSearch'}
                                secondary
                                value="Add more bottles"
                            ></ChaiHeaderButton>
                            <ChaiHeaderButton
                                label="View ledgers list"
                                onClick={() => window.location.href = '/'}
                                style={{ marginLeft: '10px', }}
                                value="View ledgers list"
                            ></ChaiHeaderButton>
                        </DialogActions>
                    }
                </Dialog>
            </div>
        );
    };

    handleComplete() { }
}

export default withStyles(styles)(ChaiMultiBottleEntry);
