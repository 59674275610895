import React from "react";
import {
    ChaiAntifraudMenuIcon,
    ChaiBottleDataMenuIcon,
    ChaiBottleInfoMenuIcon,
    ChaiChainOfCustodytMenuIcon,
    ChaiClosuresMenuIcon,
    ChaiCounterfeitMenuIcon,
    ChaiGlassMenuIcon,
    ChaiLabelsMenuIcon,
    ChaiLedgerMenuIcon,
    ChaiMeasurementsMenuIcon
} from "../ChaiMenuIcons";

export const mainMenuItemsData = [
    {
        menuTitle: 'Wine Ledger',
        icon: <ChaiLedgerMenuIcon />,
        path: '/',
        completed: false,
        hideInStepper: true
    },
    {
        menuTitle: 'Authenticator',
        icon: <ChaiChainOfCustodytMenuIcon />,
        path: '/multiBottleEntry/1/authenticator',
        completed: false,
        mandatory: true,
        valid: null,
    },
    {
        menuTitle: 'Bottle Info',
        title: 'Bottle Information',
        icon: <ChaiBottleInfoMenuIcon />,
        path: '/multiBottleEntry/1/bottleInformation',
        completed: false,
        mandatory: true,
        valid: null,
    },
    {
        menuTitle: 'Measurements',
        icon: <ChaiMeasurementsMenuIcon />,
        path: '/multiBottleEntry/1/measurements',
        completed: false,
        mandatory: true,
        valid: null,
    },
    {
        menuTitle: 'Main Label',
        icon: <ChaiLabelsMenuIcon />,
        path: '/multiBottleEntry/1/mainLabel',
        completed: false,
        mandatory: true,
        valid: null,
    },
    {
        menuTitle: 'Other Labels',
        icon: <ChaiLabelsMenuIcon />,
        path: '/multiBottleEntry/1/otherLabel',
        completed: false,
        mandatory: false,
        valid: null,
    },
    {
        menuTitle: 'Closure Capsule',
        icon: <ChaiClosuresMenuIcon />,
        path: '/multiBottleEntry/1/closureCapsule',
        completed: false,
        mandatory: true,
        valid: null,
    },
    {
        menuTitle: 'Closure Cork',
        icon: <ChaiClosuresMenuIcon />,
        path: '/multiBottleEntry/1/closureCork',
        completed: false,
        mandatory: true,
        valid: null,
    },
    {
        menuTitle: 'Glass',
        icon: <ChaiGlassMenuIcon />,
        path: '/multiBottleEntry/1/glass',
        completed: false,
        mandatory: true,
        valid: null,
    },
    {
        menuTitle: 'Antifraud',
        icon: <ChaiAntifraudMenuIcon />,
        path: '/multiBottleEntry/1/antifraud',
        completed: false,
        valid: null,
    }
];

export const secondaryMenuItemsData = [
    {
        menuTitle: 'Wine Ledger',
        icon: <ChaiLedgerMenuIcon />,
        path: '/',
        completed: false,
        hideInStepper: true
    },
    {
        menuTitle: 'Bottle Data',
        title: 'Bottle Data & Unique Conditions',
        icon: <ChaiBottleDataMenuIcon />,
        path: '/multiBottleEntry/2/bottleData',
        completed: false,
        mandatory: true,
        valid: null,
    },
    {
        menuTitle: 'Other Labels',
        icon: <ChaiLabelsMenuIcon />,
        path: '/multiBottleEntry/2/otherLabel',
        completed: false,
        mandatory: false,
        valid: null,
    },
    {
        menuTitle: 'Closure',
        icon: <ChaiClosuresMenuIcon />,
        path: '/multiBottleEntry/2/closureCork',
        completed: false,
        mandatory: true,
        valid: null,
    },
    {
        menuTitle: 'Antifraud',
        icon: <ChaiAntifraudMenuIcon />,
        path: '/multiBottleEntry/2/antifraud',
        completed: false,
        valid: null,
    },
    {
        menuTitle: 'Administrative',
        icon: <ChaiChainOfCustodytMenuIcon />,
        path: '/multiBottleEntry/2/administrative',
        completed: false,
        mandatory: true,
        valid: null,
    },
    {
        menuTitle: 'Wine Location',
        icon: <ChaiChainOfCustodytMenuIcon />,
        path: '/multiBottleEntry/2/wineLocation',
        completed: false,
        mandatory: true,
        valid: null,
    }
];
